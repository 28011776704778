import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationType } from 'src/app/enum/NotificationType';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;
  isOldPawordValid: boolean = true;
  UserId: any;
  isCPBtnDisable: boolean = false;
  constructor(formBuilder: FormBuilder,
    public userService: UserService,
    private cacheService: CacheService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private storageService: StorageService
  ) {
    this.changePasswordForm = formBuilder.group({
      'oldPwd': ['', Validators.required],
      'newPwd': ['', Validators.required],
      'confirmPwd': ['', Validators.required]
    },
      { validator: this.passwordMatchValidator }
    );
  }
  ngOnInit() {
    this.cacheService._userDetails.subscribe((res) => {
      if (res != null) {
        this.UserId = res.id;
      }
      // this.ChangePassword();
    });
  }
  passwordMatchValidator(group: AbstractControl) {
    const newPassword = group.get('newPwd')?.value;
    const confirmPassword = group.get('confirmPwd')?.value;
    return newPassword === confirmPassword ? null : { passwordsNotMatching: true };
  }
  get oldPwd() {
    return this.changePasswordForm.get('oldPwd');
  }

  get newPwd() {
    return this.changePasswordForm.get('newPwd');
  }

  get confirmPwd() {
    return this.changePasswordForm.get('confirmPwd');
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  getUserDetailsById(id: number) {
    this.userService.getUserFromID(id).subscribe((res) => {
      if (res) {
        this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
        this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
        this.cacheService.onUserDetailsChange(res);
      }
    });
  }

  toggleAlert(){
    this.isOldPawordValid = true;
  }

  ChangePassword() {
    this.isCPBtnDisable = true;
    this.userService.getUserFromID(this.UserId).subscribe((res) => {
      if (res.password == this.changePasswordForm.controls['oldPwd'].value) {
        this.userService.changePassword(this.UserId, this.changePasswordForm.controls['newPwd'].value).subscribe((res) => {
          if (res) {
            this.getUserDetailsById(this.UserId);
            this.closeModal();
            this.notificationService.notifyMessage(NotificationType.Success, `You're password updated successfully!`);
          }
          else {
            this.notificationService.notifyMessage(NotificationType.Warning, `Something went wrong!`);
          }
        });
      }
      else {
        this.isOldPawordValid = false;
        this.isCPBtnDisable = false;
      }
    });
  }

}