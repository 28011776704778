import { Component, OnInit } from '@angular/core';
import { MetaContentService } from 'src/app/services/meta-content.service';

@Component({
  selector: 'app-upcoming-examinations',
  templateUrl: './upcoming-examinations.component.html',
  styleUrls: ['./upcoming-examinations.component.css']
})
export class UpcomingExaminationsComponent implements OnInit {

  metaTitle: string = 'Upcoming Entrance Exams 2025 – JEE, NEET, BITSAT, CUET & More | AskIITians';
  metaDesc: string = 'Stay updated on upcoming entrance exams like JEE Main & Advanced, NEET, BITSAT, CUET, IISER Aptitude Test, NEST, and more. Get exam dates, eligibility, syllabus, and preparation tips at AskIITians.';

  constructor(private metaService: MetaContentService) { }

  ngOnInit(): void {
    this.updateMetaContent();
  }

  updateMetaContent() {
    this.metaService.updateMetaContent(
      null,
      this.metaDesc,
      this.metaTitle,
      null,
      'upcoming-examinations/',
      'cms',
      null
    );
  }

}
