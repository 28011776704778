import { Component, ElementRef, Renderer2, forwardRef, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryCodeModel } from '../Models/CountryCode.Model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CountryPickerComponent),
    multi: true,
  }]
})
export class CountryPickerComponent implements ControlValueAccessor {
  @Output() sendDataEvent = new EventEmitter<boolean>();
  @Input() isEditPhoneNumber!: boolean;
  isProduction: boolean = environment.production;

  isDropdownVisible = false;
  countryCodes: any[] = CountryCodeModel;
  formControl!: FormGroup;

  selectedCountry: any = this.countryCodes.find((country: any) => country.code === '+91');
  searchText: string = '';

  onTouched = () => { };

  constructor(private elementRef: ElementRef,
    public formBuilder: FormBuilder,
    private renderer: Renderer2) {
    // create form with phoneNumber field
    this.formControl = this.formBuilder.group(
      {
        phoneNumber: [''],
      }
    );
  }

  ngOnInit() {
    this.renderer.listen('body', 'click', (event) => {
      this.closeDropdownOnClickOutside(event);
    });
  }

  ngOnDestroy() {
    this.renderer.destroy();
  }

  ngOnChanges(changes: SimpleChanges) {
    // enable or disable the phoneNumber field when changes occur in Parent
    if (changes['isEditPhoneNumber']) {
      if (this.isEditPhoneNumber) {
        this.formControl.controls['phoneNumber'].enable();
      }
      else {
        this.formControl.controls['phoneNumber'].disable();
      }
    }
  }

  toggleVisiblityDropdown() {
    if (this.isEditPhoneNumber || this.isEditPhoneNumber === undefined) {
      this.isDropdownVisible = !this.isDropdownVisible
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } return true;
  }

  onSelect(country: any): void {
    this.selectedCountry = country;
    this.formControl.reset({
      phoneNumber: '',
    });
  }

  getFilteredCountryCodes(): any[] {
    const searchTextLower = this.searchText.toLowerCase();
    return this.countryCodes.filter(country =>
      country.country.toLowerCase().includes(searchTextLower) || country.code.includes(searchTextLower)
    );
  }

  writeValue(value: any) {
    if (value) {
      this.selectedCountry = this.countryCodes.find((country: any) => country.code === value.split('-')[0])
      this.formControl.controls['phoneNumber'].setValue(value.split('-')[1]);
    }
    else {
      this.formControl.controls['phoneNumber'].setValue(value);
    }
  }

  registerOnChange(fn: Function) {
    this.formControl.controls['phoneNumber'].valueChanges.subscribe((val) => fn(this.selectedCountry.code + '-' + val));
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  closeDropdownOnClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownVisible = false;
    }
  }
}
