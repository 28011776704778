<div class="faqsWrap">
	<div class="container">
		<!-- ad popup start-->
		<!-- <div id="viewSamplePopup">
			<div id="viewSampleMain">
				<div id="viewSamplediv">
					<img src="https://files.askiitians.com/static_content/Resources/images/wishCross-red.png" width="29px" height="29px" class="viewSamplecancel" id="viewSamplecancel">
					<div class="popupinput">
						<a href="/online-study-packages.aspx?utm_source=promo_on_site&amp;utm_medium=navigation&amp;utm_campaign=ecom&amp;utm_term=plain&amp;utm_content=null">
						<img src="https://files.askiitians.com/static_content/Resources/images/ecom-popup.jpg" width="821px" height="510px"></a>
					</div>
				</div>
			</div>
		</div> -->
		<div class="rou-b-m">
			<div style="text-align: justify;">
				<h1>What is askIITians?</h1>
				<p>
					AskIITians was started with the concept of helping students who leave the comfort of their homes and live in difficult circumstances to prepare for <span class="faqheiglights">JEE, NEET </span>and other <span class="faqheiglights">Engineering and Medical entrance Examinations.</span>
					The unique Model of AskIITians brings the classroom to the student’s desktop. AskIITians, through its various programs like <span class="faqheiglights">live online classroom coaching, study material, discussion forum, test series, classroom recordings </span>and many more, ensures that students get an IITian/ Doctors to share their toil and guide them in every step while the students are sitting in their own homes.
					AskIITians employs around 100+ IITians and 40+ Doctors from Top Notch Medical Colleges in India who help students in preparing for IIT JEE/NEET, mentoring them in tough times; helping them decide the streams they should choose, boost their morale and instill the confidence that an ordinary average student can also crack IIT JEE, NEET (AIPMT) and other engineering and medical exams
					In a way these IITians/Doctors at AskIITians acts not only as a teacher, but as a mentor, a companion, a well-wisher and a counselor.
				</p>
			</div>
		</div>


		<div class="faqsListWrap">

			<h2>FAQ's</h2>
			
	  
			<button class="faqsAccordion">What is askIITians?</button>
			<div class="faqpanel">
			  <p>
				AskIITians was started with the concept of helping students who leave the comfort of their homes and live in difficult circumstances to prepare for <span class="faqheiglights">JEE, NEET </span>and other <span class="faqheiglights">Engineering and Medical entrance Examinations.</span>
				The unique Model of AskIITians brings the classroom to the student’s desktop. AskIITians, through its various programs like <span class="faqheiglights">live online classroom coaching, study material, discussion forum, test series, classroom recordings </span>and many more, ensures that students get an IITian/ Doctors to share their toil and guide them in every step while the students are sitting in their own homes.
				AskIITians employs around 100+ IITians and 40+ Doctors from Top Notch Medical Colleges in India who help students in preparing for IIT JEE/NEET, mentoring them in tough times; helping them decide the streams they should choose, boost their morale and instill the confidence that an ordinary average student can also crack IIT JEE, NEET (AIPMT) and other engineering and medical exams
				In a way these IITians/Doctors at AskIITians acts not only as a teacher, but as a mentor, a companion, a well-wisher and a counselor.
			  </p>
			</div>
			
	  
			<button class="faqsAccordion">What are the benefits of askIITians?</button>
			<div class="faqpanel">
				<ul>
				  <li><span class="faqheiglights">AskIITians brings the learning at the students' desktop, </span>so the student can get the unmatched guidance from IITians and Doctors in secure and comfortable environment of his home. It increases his confidence level and concentration</li>
				  <li>Through <span class="faqheiglights">One to one coaching the students get proper attention </span>from the faculty .The easy interaction with the mentor helps student to get an overall development</li>
				  <li>The<span class="faqheiglights"> batch sizes are small </span>(not exceeding 30), which allows the teacher to now each student personally .This helps the student to open up with the teacher and discuss his doubts more clearly. The friendly environment created in this way leads to a clear mind. Also the small focused group makes him more competitive towards his goal</li>
				  <li>The biggest advantage of askIITians way of teaching as compared to a conventional model is that here all <span class="faqheiglights">the live classes get recorded </span>simultaneously and the download link gets available in the students’ account. He can use this feature not only to make up for his missed classes but also for the unlimited number of revisions</li>
				  <li>AskIITians believes that the doubt removal is an integral part of any course preparation which should be handled properly. No doubt should be unanswered and therefore askIITians has a continuous doubt removal process <span class="faqheiglights">through regular doubt removal sessions and the 24*7 online discussion forums.</span> Unlike the conventional mode where student have to wait for the next scheduled class to clear his doubts, askIITians manages the online discussion forum where student can post his doubts anytime and get the solution</li>
				  <li>The <span class="faqheiglights">study material is sent to the student in hard copy, which </span>includes books on various topics along with the practice papers. The test series is online which helps the student in improvement of the weak areas with the help of in-depth analysis of the student’s performance. <span class="faqheiglights">AskIITians software generates students’ performance analysis for various parameters </span>like difficulty analysis, topic wise analysis etc. For example, in a school exam, if the student gets full marks for any question, he usually thinks that it is a strong area for him. However, in our tests, even if he gets a right answer, AskIITians compares the time taken by him to solve this problem with the time taken by topper and average time taken by the other students. So now, He knows whether he needs improvement in speed which is very important in any competitive exam</li>
				  <li>AskIITians firmly believes that <span class="faqheiglights">parental control and monitoring </span>helps in the growth of the student. With the various features offered, the parents get an upper hand here
				  <ul>
					<li>In the complete course, <span class="faqheiglights">AskIITians call for 2 parent teacher meeting </span>where parents get a chance to directly interact with the teacher and discuss any doubts / changes to improve the performance of student</li>
					<li>Unlike the conventional coaching classes, AskIITians allows parents to participate in class and <span class="faqheiglights">check the quality of teaching.</span> In case they find any issue with teaching, they can raise a complaint which will be resolved within 24 hours. In addition to this, after every 15 day a <span class="faqheiglights">feedback survey form </span>is send to parent and the student about teachers as well as the service delivery</li>
					<li><span class="faqheiglights">All the test reports and analysis are available at the students account </span>for parents’ continuous monitoring</li>
					<li><span class="faqheiglights">AskIITians informs parents about 3 consecutive class bunks and any single online test bunk. </span>The parents are contacted and asked for explanation. Till parents’ further response, the student’s account gets deactivated.</li>
				  </ul>
				</li>
			  </ul>
			</div>
		  
	  
		  <button class="faqsAccordion">For Which Entrance examinations askIITians is preparing students?</button>
			<div class="faqpanel">
			  <p>AskIITians prepares students for <span class="faqheiglights"><a href="https://www.askiitians.com/package/two-year-classroom-program-for-jee-main-advanced-and-other-engineering-exams">IIT JEE (Main)</a>, <a href="https://www.askiitians.com/package/one-year-classroom-program-for-jee-main-advanced-and-other-engineering-exams">IIT JEE (Advanced)</a>, BITSAT, <a href="https://www.askiitians.com/package/two-year-classroom-program-for-pmt-aiims-aipmt-sle-and-other-medical-exams">NEET</a>, AFMC and other engineering and medical entrance examinations.</span> Apart from this askIITians also help in preparation of JSO/KVPY/NTSE/ boards/ foundation.</p>
			  <p>Further AskIITians also teaches for <a href="https://www.askiitians.com/package/sat-program-for-grade-11-and-12">SAT Examination.</a></p>
			</div>
		  
	  
		  <button class="faqsAccordion">How will the Online Classes be run? Will the student be able to interact with the teacher?</button>
			<div class="faqpanel">
			  <p>Our online classes are totally interactive in nature where teacher ensures that teacher and students are on the same level of teaching and understanding. Student can interact with teacher via headphone/earphone or web chat on the online classroom platform. AskIITians brings the learning at the students’ desktop, so the student can get the unmatched guidance from IITians/Doctors in secure and comfortable environment of his home</p>
			</div>
			
	  
		  <button class="faqsAccordion">What will be the timings of the Online Classes?</button>
			<div class="faqpanel">
			  <p>AskIITians assures the timing of the classes fall after the school timings as per convenience of the students. Some courses allow more customized timing options.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">What are the requirements to take Online Classes without any problem?</button>
			<div class="faqpanel">
			  <ul>
				<li>The students have to arrange the following equipment for smooth running of live and interactive classes:
				  <ul>
					<li>A Laptop/Desktop of latest configuration </li>
					<li>Headphone/Microphone</li>
					<li>Internet connection with min. <span id=":141.co" dir="ltr">2 mbps in LAN connection else 4g through wi-fi</span></li>
					<li>Webcam (optional)</li>
				  </ul>
				</li>
			  </ul>
			</div>
		  
	  
		  <button class="faqsAccordion">What type of Study Material will be given to students?</button>
			<div class="faqpanel">
			  <p>Study materials in form of books for different subjects (exclusively designed by IITians/Doctors) and daily practice papers are made available to the students, which varies according to the course packages. The study material is send to the students through courier at the start of the course commencement. </p>
			</div>
		  
	  
		  <button class="faqsAccordion">Will there be any Test Series for students? How the performance will be judged?</button>
			<div class="faqpanel">
			  <p>There are online All India Test series, for engineering entrance examination and for Pre Medical examinations, developed by the group of IITians and experienced medical faculties. These test series offer carefully molded and structured tests covering the entire syllabus of the examinations. These tests help students analyze their understanding, structure their approach and enhance their test taking capability to succeed in the final examination.</p>
			  <p>A detailed analysis of the performance of student based on different parameters is carried out to bring out students ‘weak as well as strong point. This in depth analysis which includes Topic Wise Analysis, Difficulty Level Analysis, Comparison with Toppers, and Time Based Analysis among other analyses helps our experts to provide proper guidance to the student for further improvement.</p>
			  <p>This method helps in the growth of the student by focusing more on the concerned area.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">How to arrange a Free Class with an IITian Teacher?</button>
			<div class="faqpanel">
			  <p>The free class is just one step farther to the student .The student has to register on <span class="faqheiglights"><a href="https://www.askiitians.com/">askIITians.com </a> </span>and our Experts Team will contact the students for their customized requirement .On the basis of the requirement given by the student our team arranges the free class with IITian/Doctors. The student can interact with the IITian/Doctor and can ask any doubt. The IITian/Doctors will serve as a mentor and counselor along with being a teacher.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">Are the sessions recorded? How can I access to the recordings?</button>
			<div class="faqpanel">
			  <p>The Live lectures get recorded simultaneously and the link gets available in the account of student as soon as lecture gets over. This unique offering of AskIITians gives students the advantage of never missing any class and unlimited revision of the topics anytime. </p>
			  <p>The student can access the recording anytime-anywhere by logging into his/her askIITians account.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">Can I get extra doubt removal sessions for clearing Doubts?</button>
			<div class="faqpanel">
			  <p>AskIITians focuses on building the strong fundamentals to the students , for which it conducts regular doubt clearing sessions , which not only encourages the student to come up with different doubts without any hesitation but also the innovative ways of solving any problem. It develops the overall personality of the student.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">What is the Success Record of Student Selections?</button>
			<div class="faqpanel">
			  <p>AskIITians delivered the promised result in form of 478 selections in <span class="faqheiglights">JEE Advanced in 2017.</span> Out of all students enrolled in different courses of AskIITians, 902 made it through JEE Main which means that <span class="faqheiglights">almost every 1 out of two students cracked JEE Advanced.</span></p>
			  <p>In NEET, 324 selections once again marked the winning spirit of AskIITians. The numbers itself speaks about the success record of AskIITians. To view our complete result, <a href="https://www.askiitians.com/results/"> click here</a></p>
			</div>
		  
	  
		  <button class="faqsAccordion">What will be the mode of payment for FEE?</button>
			<div class="faqpanel">
			  <p>The different modes of Fee payment are available at askIITians like <span class="faqheiglights">Online Transaction </span>in form of <span class="faqheiglights">Credit card, Debit card and Net banking payment along with the conventional Cheque or cash deposit at Bank.</span></p>
			  <p>You can also pay through Money exchange or Paypal link in case you are living abroad.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">How to arrange a Free Demo Class with an IITian/Doctor Teacher?</button>
			<div class="faqpanel">
			  <p>The free class is just one step away to the student. The student has to register on <a href="https://www.askiitians.com/">askIITians</a> and our Experts Team will contact the students for their customized requirement. On the basis of the requirement given by the student, our team arranges the free class with IITian/Doctors. The student can interact with the IITian/Doctor and can ask any doubt. The IITian/Doctors will serve as a mentor and counselor along with being a teacher.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">What if I miss my regular sessions?</button>
			<div class="faqpanel">
			  <p>You can view the recordings from your account after 24 hours.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">Who will be the trainer in the classroom? What is their qualification?</button>
			<div class="faqpanel">
			  <p>These are the experts who themselves have gone rigorous hard work and have cracked prestigious exams like JEE (Main), JEE (Advanced), BITSAT, NEET, AFMC. We believe, when it comes to competitive exams, we need to adopt a smart strategic approach for preparation. Our experts will not only polish your talent but also help you in lots of way, starting from note making, time management to revision tips and tricks.</p>
			</div>
		  
	  
		  <button class="faqsAccordion">What type of Study Material will be given to students?</button>
		  <div class="faqpanel">
			<p>Study materials in form of books for different subjects (exclusively designed by IITians/Doctors) and daily practice papers are made available to the students, which varies according to the <a href="https://www.askiitians.com/packages/packages.aspx">Course Packages</a>. The study material is send to the students through courier at the start of the course commencement. </p>
		  </div>
		  
	  
		  <button class="faqsAccordion">Will there be any Test Series for students? How the performance will be judged?</button>
		  <div class="faqpanel">
			<p>There are online All India Test series, for engineering entrance examination and for Pre Medical examinations, developed by the group of IITians and experienced medical faculties. These test series offer carefully molded and structured tests covering the entire syllabus of the examinations. These tests help students analyze their understanding, structure their approach and enhance their test taking capability to succeed in the final examination.</p>
			<p>A detailed analysis of the performance of student based on different parameters is carried out to bring out students ‘weak as well as strong point. This in depth analysis which includes Topic Wise Analysis, Difficulty Level Analysis, Comparison with Toppers, and Time Based Analysis among other analyses helps our experts to provide proper guidance to the student for further improvement.</p>
			<p>This method helps in the growth of the student by focusing more on the concerned area.</p>
		  </div>
		  
	  
		  <button class="faqsAccordion">How do I enroll with AskIITians?</button>
		  <div class="faqpanel">
			<p>You just have to register on <span class="faqheiglights"><a href="https://www.askiitians.com/">www.askIITians.com </a></span>and our Experts Team will get in touch with you for your requirement whether enrollment, study material, test series or free demo class with IITians/Doctors passed out from prominent medical colleges. </p>
		  </div>
		  
	  
		  <button class="faqsAccordion">Do we have any coaching center at my location?</button>
		  <div class="faqpanel">
			<p>No, as of now we only teach through online interactive classes. The unique Model of AskIITians brings the classroom to the students’ desktop. AskIITians helps through its various programs like <span class="faqheiglights">live online classroom coaching, discussion forum, test series, classroom recordings </span>and many more.</p>
		  </div>
	  
		</div>


		<script>
			var acc = document.getElementsByClassName("faqsAccordion");
			var i;
			for (i = 0; i < acc.length; i++) {
			acc[i].addEventListener("click", function() {
			// Close other panels
			for (let j = 0; j < acc.length; j++) {
			if (acc[j] !== this) {
			acc[j].classList.remove("faqactive");
			acc[j].nextElementSibling.classList.remove("faqopen");
			}
			}
			
			// Toggle the clicked accordion's active state
			this.classList.toggle("faqactive");
			var panel = this.nextElementSibling;
			
			// Toggle panel visibility with transition
			if (panel.classList.contains("faqopen")) {
			panel.classList.remove("faqopen");
			} else {
			panel.classList.add("faqopen");
			}
			});
			}
		</script>

	</div>
</div>
	<!-- <script type="text/javascript" src="https://files.askiitians.com/static_content/Resources/scripts/jquery.min.js"> </script> -->


	

	<!-- <script type="application/ld+json">
	{
	"@context": "https://schema.org",
	"@type": "FAQPage",
	"mainEntity": [{
	"@type": "Question",
	"name": "What are the benefits of askIITians?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text": "AskIITians brings the learning at the students' desktop, so the student can get the unmatched guidance from IITians and Doctors in secure and comfortable environment of his home. It increases his confidence level and concentration
	Through One to one coaching the students get proper attention from the faculty .The easy interaction with the mentor helps student to get an overall development
	The batch sizes are small (not exceeding 30), which allows the teacher to now each student personally .This helps the student to open up with the teacher and discuss his doubts more clearly. The friendly environment created in this way leads to a clear mind. Also the small focused group makes him more competitive towards his goal
	The biggest advantage of askIITians way of teaching as compared to a conventional model is that here all the live classes get recorded simultaneously and the download link gets available in the students’ account. He can use this feature not only to make up for his missed classes but also for the unlimited number of revisions
	AskIITians believes that the doubt removal is an integral part of any course preparation which should be handled properly. No doubt should be unanswered and therefore askIITians has a continuous doubt removal process through regular doubt removal sessions and the 24*7 online discussion forums. Unlike the conventional mode where student have to wait for the next scheduled class to clear his doubts, askIITians manages the online discussion forum where student can post his doubts anytime and get the solution
	The study material is sent to the student in hard copy, which includes books on various topics along with the practice papers. The test series is online which helps the student in improvement of the weak areas with the help of in-depth analysis of the student’s performance. AskIITians software generates students’ performance analysis for various parameters like difficulty analysis, topic wise analysis etc. For example, in a school exam, if the student gets full marks for any question, he usually thinks that it is a strong area for him. However, in our tests, even if he gets a right answer, AskIITians compares the time taken by him to solve this problem with the time taken by topper and average time taken by the other students. So now, He knows whether he needs improvement in speed which is very important in any competitive exam
	AskIITians firmly believes that parental control and monitoring helps in the growth of the student. With the various features offered, the parents get an upper hand here
	In the complete course, AskIITians call for 2 parent teacher meeting where parents get a chance to directly interact with the teacher and discuss any doubts / changes to improve the performance of student
	Unlike the conventional coaching classes, AskIITians allows parents to participate in class and check the quality of teaching. In case they find any issue with teaching, they can raise a complaint which will be resolved within 24 hours. In addition to this, after every 15 day a feedback survey form is send to parent and the student about teachers as well as the service delivery
	All the test reports and analysis are available at the students account for parents’ continuous monitoring
	AskIITians informs parents about 3 consecutive class bunks and any single online test bunk. The parents are contacted and asked for explanation. Till parents’ further response, the student’s account gets deactivated."
	}
	}, {
	"@type": "Question",
	"name": "For Which Entrance examinations askIITians is preparing students?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text": "AskIITians prepares students for IIT JEE (Main), IIT JEE (Advanced), BITSAT, NEET, AFMC and other engineering and medical entrance examinations. Apart from this askIITians also help in preparation of JSO/KVPY/NTSE/ boards/ foundation.Further AskIITians also teaches for SAT Examination."
	}
	}, {
	"@type": "Question",
	"name": "How will the Online Classes be run? Will the student be able to interact with the teacher?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text": "Our online classes are totally interactive in nature where teacher ensures that teacher and students are on the same level of teaching and understanding. Student can interact with teacher via headphone/earphone or web chat on the online classroom platform. AskIITians brings the learning at the students’ desktop, so the student can get the unmatched guidance from IITians/Doctors in secure and comfortable environment of his home"
	}
	}, {
	"@type": "Question",
	"name": "What will be the timings of the Online Classes?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text": "AskIITians assures the timing of the classes fall after the school timings as per convenience of the students. Some courses allow more customized timing options."
	}
	}, {
	"@type": "Question",
	"name": "What are the requirements to take Online Classes without any problem?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"The students have to arrange the following equipment for smooth running of live and interactive classes:A Laptop/Desktop of latest configuration,Headphone/Microphone,Internet connection with min. 2 mbps in LAN connection else 4g through wi-fi,Webcam (optional)"}
	},
	{
	"@type": "Question",
	"name": "What type of Study Material will be given to students?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"Study materials in form of books for different subjects (exclusively designed by IITians/Doctors) and daily practice papers are made available to the students, which varies according to the course packages. The study material is send to the students through courier at the start of the course commencement."}
	},
	{
	"@type": "Question",
	"name": "Will there be any Test Series for students? How the performance will be judged?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"There are online All India Test series, for engineering entrance examination and for Pre Medical examinations, developed by the group of IITians and experienced medical faculties. These test series offer carefully molded and structured tests covering the entire syllabus of the examinations. These tests help students analyze their understanding, structure their approach and enhance their test taking capability to succeed in the final examination.A detailed analysis of the performance of student based on different parameters is carried out to bring out students ‘weak as well as strong point. This in depth analysis which includes Topic Wise Analysis, Difficulty Level Analysis, Comparison with Toppers, and Time Based Analysis among other analyses helps our experts to provide proper guidance to the student for further improvement.This method helps in the growth of the student by focusing more on the concerned area."}
	},
	{
	"@type": "Question",
	"name": "How to arrange a Free Class with an IITian Teacher?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"The free class is just one step farther to the student .The student has to register on askIITians.com and our Experts Team will contact the students for their customized requirement .On the basis of the requirement given by the student our team arranges the free class with IITian/Doctors. The student can interact with the IITian/Doctor and can ask any doubt. The IITian/Doctors will serve as a mentor and counselor along with being a teacher."}
	},
	{
	"@type": "Question",
	"name": "Are the sessions recorded? How can I access to the recordings?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"The Live lectures get recorded simultaneously and the link gets available in the account of student as soon as lecture gets over. This unique offering of AskIITians gives students the advantage of never missing any class and unlimited revision of the topics anytime.The student can access the recording anytime-anywhere by logging into his/her askIITians account."}
	},
	{
	"@type": "Question",
	"name": "What is the Success Record of Student Selections?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"AskIITians delivered the promised result in form of 478 selections in JEE Advanced in 2017. Out of all students enrolled in different courses of AskIITians, 902 made it through JEE Main which means that almost every 1 out of two students cracked JEE Advanced."}
	},
	{
	"@type": "Question",
	"name": "What will be the mode of payment for FEE?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"The different modes of Fee payment are available at askIITians like Online Transaction in form of Credit card, Debit card and Net banking payment along with the conventional Cheque or cash deposit at Bank.You can also pay through Money exchange or Paypal link in case you are living abroad."}
	},
	{
	"@type": "Question",
	"name": "Can students pay the fee in Installments?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"Yes, Installment option is available to students for selected courses."}
	},
	{
	"@type": "Question",
	"name": "How to arrange a Free Demo Class with an IITian/Doctor Teacher?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"The free class is just one step away to the student. The student has to register on askIITians and our Experts Team will contact the students for their customized requirement. On the basis of the requirement given by the student, our team arranges the free class with IITian/Doctors. The student can interact with the IITian/Doctor and can ask any doubt. The IITian/Doctors will serve as a mentor and counselor along with being a teacher."}
	},
	{
	"@type": "Question",
	"name": "What if I miss my regular sessions?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"You can view the recordings from your account after 24 hours."}
	},
	{
	"@type": "Question",
	"name": "Who will be the trainer in the classroom? What is their qualification?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"These are the experts who themselves have gone rigorous hard work and have cracked prestigious exams like JEE (Main), JEE (Advanced), BITSAT, NEET, AFMC. We believe, when it comes to competitive exams, we need to adopt a smart strategic approach for preparation. Our experts will not only polish your talent but also help you in lots of way, starting from note making, time management to revision tips and tricks."}
	},
	{
	"@type": "Question",
	"name": "What will be the timings of the Online Classes?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"AskIITians assures the timing of the classes fall after the school timings as per convenience of the students. Some courses allow more customized timing options."}
	},
	{
	"@type": "Question",
	"name": "What type of Study Material will be given to students?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"Study materials in form of books for different subjects (exclusively designed by IITians/Doctors) and daily practice papers are made available to the students, which varies according to the Course Packages. The study material is send to the students through courier at the start of the course commencement."}
	},
	{
	"@type": "Question",
	"name": "Will there be any Test Series for students? How the performance will be judged?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"There are online All India Test series, for engineering entrance examination and for Pre Medical examinations, developed by the group of IITians and experienced medical faculties. These test series offer carefully molded and structured tests covering the entire syllabus of the examinations. These tests help students analyze their understanding, structure their approach and enhance their test taking capability to succeed in the final examination.A detailed analysis of the performance of student based on different parameters is carried out to bring out students ‘weak as well as strong point. This in depth analysis which includes Topic Wise Analysis, Difficulty Level Analysis, Comparison with Toppers, and Time Based Analysis among other analyses helps our experts to provide proper guidance to the student for further improvement.This method helps in the growth of the student by focusing more on the concerned area."}
	},
	{
	"@type": "Question",
	"name": "How do I enroll with AskIITians?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"You just have to register on www.askIITians.com and our Experts Team will get in touch with you for your requirement whether enrollment, study material, test series or free demo class with IITians/Doctors passed out from prominent medical colleges."}
	},
	{
	"@type": "Question",
	"name": "Do we have any coaching center at my location?",
	"acceptedAnswer": {
	"@type": "Answer",
	"text":"No, as of now we only teach through online interactive classes. The unique Model of AskIITians brings the classroom to the students’ desktop. AskIITians helps through its various programs like live online classroom coaching, discussion forum, test series, classroom recordings and many more."}
	}]
	}
	</script> -->



