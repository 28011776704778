

<section class="upcomingexaminations-wrap">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Upcoming Examinations</h2>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-12"><div class="streamHeading"><img src="/assets/images/mathematics.png" alt="PCM"> <div class="pcmbName"><strong>Stream</strong>PCM</div></div></div>
            <div class="clear-both"></div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>JEE Main <span>(Paper 1 & Paper 2)</span></li>
                    <li class="registration"><strong>Registration Commencement</strong> 1 Feb 2025 - 25 Feb 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong> Last week of March</li>
                    <li class="examDate"><strong>ExaminationDate</strong>1st week of April</li>
                    <li class="resultDate"><strong>Result</strong>15 April 2025</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>JEE Advanced</li>
                    <li class="registration"><strong>Registration Commencement</strong> 23 April 2025 - 02 May 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong> 11-18 May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>18 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>02 June 2025</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>AAT</li>
                    <li class="registration"><strong>Registration Commencement</strong> 02-03 June 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>02-05 June 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>05 June 2025</li>
                    <li class="resultDate"><strong>Result</strong>08 June 2025</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>BITSAT</li>
                    <li class="registration"><strong>Registration Commencement</strong>21 Jan - 18 April 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>23 May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>26-30 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>09 July 2025</li>
                    <li><b>Session-1</b></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>BITSAT</li>
                    <li class="registration"><strong>Registration Commencement</strong>26 May-20 June 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>20 June 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>22-30 June 2025</li>
                    <li class="resultDate"><strong>Result</strong>10 July 2025</li>
                    <li><b>Session-2</b></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>IISER Aptitude Test (IAT)</li>
                    <li class="registration"><strong>Registration Commencement</strong>05 March - 15 April 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>15 May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>25 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>NEST</li>
                    <li class="registration"><strong>Registration Commencement</strong>17 February 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>02 June 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>22 June 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of July</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>CUET(UG)</li>
                    <li class="registration"><strong>Registration Commencement</strong>1st week of Feb - Last week of March</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>1st week of May</li>
                    <li class="examDate"><strong>ExaminationDate</strong>20-30 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>Last week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>CMI</li>
                    <li class="registration"><strong>Registration Commencement</strong>1 March 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>2nd week of May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>24 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>NATA</li>
                    <li class="registration"><strong>Registration Commencement</strong>3 Feb 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>Last week of Feb 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>01 March 2025 onwards till June 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>VITEEE</li>
                    <li class="registration"><strong>Registration Commencement</strong>Upto 31 March 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>2nd week of April 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>21-27 April 2025</li>
                    <li class="resultDate"><strong>Result</strong>30 April 2025</li>
                    </ul>
                </div>
            </div>
            
        </div>

        <br><br>

        <div class="row">
            <div class="col-md-12"><div class="streamHeading pcbBg"><img src="/assets/images/biology.png" alt="PCB"> <div class="pcmbName"><strong>Stream</strong>PCB</div></div></div>
            <div class="clear-both"></div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>NEET (UG)</li>
                    <li class="registration"><strong>Registration Commencement</strong> 2nd week of February</li>
                    <li class="admitCard"><strong>Admit Card Available</strong> Last week of April 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>1st week of May</li>
                    <li class="resultDate"><strong>Result</strong>Second week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>NEST</li>
                    <li class="registration"><strong>Registration Commencement</strong>17 February 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>02 June 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>22 June 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of July</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>IISER Aptitude Test (IAT)</li>
                    <li class="registration"><strong>Registration Commencement</strong>05 March - 15 April 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>15 May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>25 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>Second week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>CUET(UG)</li>
                    <li class="registration"><strong>Registration Commencement</strong>1st week of Feb - Last week of March</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>1st week of May</li>
                    <li class="examDate"><strong>ExaminationDate</strong>20-30 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>Last week of June</li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>BITSAT</li>
                    <li class="registration"><strong>Registration Commencement</strong>21 Jan - 18 April 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>23 May 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>26-30 May 2025</li>
                    <li class="resultDate"><strong>Result</strong>09 July 2025</li>
                    <li><b>Session-1</b></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3">
                <div class="upcomingExam-pcb-block">
                    <ul>
                    <li class="examName"><strong>Exam Name</strong>BITSAT</li>
                    <li class="registration"><strong>Registration Commencement</strong>26 May-20 June 2025</li>
                    <li class="admitCard"><strong>Admit Card Available</strong>20 June 2025</li>
                    <li class="examDate"><strong>ExaminationDate</strong>22-30 June 2025</li>
                    <li class="resultDate"><strong>Result</strong>10 July 2025</li>
                    <li><b>Session-2</b></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</section>