import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from 'src/app/services/cache.service';
import { SendOTPMessageService } from 'src/app/services/send-otpmessage.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { environment } from 'src/environments/environment';
import { LoginWithEmailComponent } from '../login-with-email/login-with-email.component';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.css'],
})
export class OtpVerificationComponent implements OnInit {
  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() isOtpVerfied: EventEmitter<any> = new EventEmitter();
  @Output() otp: EventEmitter<string> = new EventEmitter();
  @Output() VerificationId: EventEmitter<number> = new EventEmitter();
  @Input() phoneNo: string = '';
  @Input() isLoading: boolean = false;

  isProduction: boolean = environment.production;
  showOTPInput: boolean = false;
  showResendOTP: boolean = false;
  mobileNumError: string = '';
  confirmationResult!: any;
  isOTPSent: boolean = false;
  isUserExists: boolean = false;
  isOTPVerfiedMessage: boolean = true;
  form!: FormGroup;
  ValidatebuttonHide: boolean = false;
  verificationCode!: string;
  user: any;
  gID!: any;
  separateDialCode = true;
  otpVerified: Boolean = false;
  toValidate: boolean = false;
  generatedOTP!: number;
  verificationId: number | undefined;
  modalRefMobileResentOTP: any;
  isResendOTP: boolean = false;
  isOTPVerfied: boolean = false;
  isOTPVerfiedFromDB: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private cachaService: CacheService,
    private sendOTPService: SendOTPMessageService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      verificationCode1: ['', Validators.required],
      verificationCode2: ['', Validators.required],
      verificationCode3: ['', Validators.required],
      verificationCode4: ['', Validators.required],
      verificationCode5: ['', Validators.required],
      verificationCode6: ['', Validators.required],
    });
    if (this.isOTPSent == false) {
      this.sendLoginCode();
    }
  }

  get f() {
    return this.form.controls;
  }

  keyUpEvent(e: any) {
    var target = e.target;

    var maxLength = parseInt(target.attributes['maxlength'].value, 10);
    var myLength = target.value.length;

    if (myLength >= maxLength) {
      var next = target;
      while ((next = next.nextElementSibling)) {
        if (next == null) break;
        if (next.tagName.toLowerCase() == 'input') {
          next.focus();
          break;
        }
      }
    } else if (myLength < maxLength) {
      var prev = target;
      while ((prev = prev.previousElementSibling)) {
        if (prev == null) break;

        if (prev.tagName.toLowerCase() == 'input') {
          prev.focus();
          break;
        }
      }
    }
  }

  resendOTP() {
    this.isResendOTP = true;
    this.sendLoginCode();
    this.form.controls['verificationCode1'].reset();
    this.form.controls['verificationCode2'].reset();
    this.form.controls['verificationCode3'].reset();
    this.form.controls['verificationCode4'].reset();
    this.form.controls['verificationCode5'].reset();
    this.form.controls['verificationCode6'].reset();
  }
  SendToAnotherNumber() {
    setTimeout(() => {
      this.activeModal.close();
    }, 100);
    this.modalRefMobileResentOTP = this.modalService.open(LoginWithEmailComponent, {
      centered: true,
      backdrop: 'static',
    });
    this.modalRefMobileResentOTP.componentInstance.isResendOTP = true;
  }

  validateOTP() {
    this.isOTPVerfied = true;
    this.otpAddition();
    if (this.generatedOTP == Number(this.verificationCode)) {
      this.isOtpVerfied.emit(true);
      this.otp.emit(this.verificationCode);
      this.VerificationId.emit(this.verificationId);
      this.isOTPVerfiedFromDB= true;
    } else {
      this.isOTPVerfied = false;
      this.isOTPVerfiedFromDB = false;
    }
  }

  sendLoginCode() {
    let phoneNo = this.storageService.getItem(StorageKey.MOBILE_NO, StorageType.LOCAL);
    if (!phoneNo) {
      phoneNo = this.phoneNo;
    }
    this.sendOTPService.sendOTP(phoneNo).subscribe({
      next: (response) => {
        if (response.isSuccess) {
          if (this.isProduction) {
            this.verificationId = response.id;
            this.generatedOTP = response.otp;
            this.otpVerified = true;
          }
          else {
            this.otpVerified = true;
            this.verificationId = response.id;
            this.generatedOTP = response.otp;
            alert('OTP:' + response.otp);
          }
        } else {
          this.otpVerified = false;
        }
      },
      error: (error) => {
        this.otpVerified = false;
      },
      complete: () => { },
    });
  }

  verifyLoginCode() {
    this.otpAddition();
  }

  otpAddition() {
    //this.isLoading= true;
    this.verificationCode =
      this.form.value.verificationCode1 +
      this.form.value.verificationCode2 +
      this.form.value.verificationCode3 +
      this.form.value.verificationCode4 +
      this.form.value.verificationCode5 +
      this.form.value.verificationCode6;
  }
  getUserDetailsById(id: number) {
    this.isLoading = false;
    this.userService.getUserFromID(id).subscribe((res) => {
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.SESSION);
      this.storageService.setItem(StorageKey.USER, JSON.stringify(res), 'session');
      this.cachaService.onUserDetailsChange(res);
    });
  }
  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }
  addGradeID() {
    return this.gID;
  }
  closeModal() {
    this.activeModal.close();
  }
}
