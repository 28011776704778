import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SideNavComponent } from './layout/side-nav/side-nav.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TrialUserComponent } from './popupModel/trial-user/trial-user.component';
import { FreeTrialNotifyComponent } from './popupModel/free-trial-notify/free-trial-notify.component';
import { GoogleRECAPTCHAModule } from './popupModel/google-recaptcha/google-recaptcha.module';
import { ChangePasswordModule } from './popupModel/change-password/change-password.module';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent, //add module --done
    TrialUserComponent, //add module  --done
    FreeTrialNotifyComponent,  //add module  --done
    SideNavComponent, //add module --done
    PageNotFoundComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
    GoogleRECAPTCHAModule,
    // ChangePasswordModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
