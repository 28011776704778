
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@3.4.1/dist/css/bootstrap.min.css" integrity="sha384-HSMxcRTRxnN+Bdg0JdbxYKrThecOKuH5zCYotlSAcp1+c8xmyTe9GYg1l9a69psu" crossorigin="anonymous">

<body data-spy="scroll" data-target=".navbar" data-offset="80">
    <form id="form1" runat="server">
        <!-- Top Navigation -->
        <!-- <nav class="navbar navbar-fixed-top">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#jhNavbar">
                        <i class="fa fa-bars" aria-hidden="true"></i>
                    </button>
                    <a class="navbar-brand" href="#"><img src="https://files.askiitians.com/static_content/Resources/images/jh-images/logo.png" class="header-logo" alt="Logo" /></a>
                    <a href="" onclick="ga('send', 'event', 'Book Class', 'submit', window.location.href, 0, 0);" class="res-juheader-cta tempUrl">BOOK A FREE CLASS</a>
                </div>
                <div class="collapse navbar-collapse" id="jhNavbar">
                    <ul class="nav navbar-nav navbar-right">
                        <li class=""><a href="#why" class="nav-link-ltr ">Why Us</a></li>
                        <li><a href="#benefits" class="nav-link-ltr">Benefits</a></li>
                        <li><a href="#courses" class="nav-link-ltr">Courses</a></li>
                        <li><a href="#work" class="nav-link-ltr">How It Works</a></li>
                        <li><a href="" onclick="ga('send', 'event', 'Book Header', 'click_text', window.location.href, 0, 0);" class="juheader-cta tempUrl">BOOK A FREE CLASS</a></li>
                    </ul>
                </div>
            </div>
        </nav> -->
        <!-- End Top Navigation -->
        <!-- Header Section -->
        <header>
            <div class="head-txt">
                <h1>Junior Hacker : Coding Made Easy</h1>
                <p class="head-sub-txt">Live coding classes to unleash your child's potential</p>
                <p class="head-sub-txt2">One on One live coding classes for age 6 -14 </p>
                <p class="head-sub-txt2 mt-10">Improving kids concentration, logical & problem solving skills</p>
                <!-- <%--<a href="../jh-form.aspx?sourceId=65" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta">BOOK A FREE CLASS</a>--%> -->
                <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS</a>
                <div class="down-arrow">
                    <a href="javascript:void(0)"><i class="fa fa-angle-double-down" aria-hidden="true"></i></a>

                </div>
            </div>
        </header>
        <!-- End Header Section -->
        <!-- Kids Course Section -->
        <section class="kids-courses" id="why">
            <div class="heading-txt">
                <h2>Whats in here for your kids?</h2>
            </div>
            <div class="kids-courses-section">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_1Wy2m3.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>Game Development</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_KO31Fh.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>
                                        Machine
                  Learning
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_m9ub4f.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>
                                        Face
                  Recognition
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_NXqR2d.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>
                                        App
                  Development
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets9.lottiefiles.com/packages/lf20_sFBr0l/snscit.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>Web Development</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-4 col-md-2">
                            <div class="kcs-container">
                                <div class="kcs-icon">
                                    <!-- <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_09gka5rv.json" background="transparent"
                                        speed="1" loop autoplay></lottie-player> -->
                                </div>
                                <div class="ksc-txt">
                                    <p>Virtual Reality</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <!-- End Kids Course Section -->
        <!-- Why Coding Section -->
        <section class="why-coding">
            <div class="heading-txt">
                <h2>Why coding for all-round development of students?</h2>
            </div>
            <div class="wc-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="wcc-img">
                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/why-coding.png" alt="Why Codding Image" />
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="wcc-txt">
                                <p>Our curriculum will Improve kid's -</p>
                                <ul>
                                    <li>Logic and concentration</li>
                                    <li>Problem solving skills</li>
                                    <li>Strategic thinking </li>
                                    <li>Self-motivativation</li>
                                    <li>Self-confidence</li>
                                </ul>
                                <p>All these will bring out the creator in your child</p>


                            </div>
                        </div>
                    </div>
                    <div class="wcc-cta">
                        <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS</a>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Why Coding Section -->
        <!-- Benefits Section -->
        <section class="benefits" id="benefits">
            <div class="heading-txt">
                <h2>Benefits of our curriculum</h2>
            </div>
            <div class="benefits-container">
                <div class="container">
                    <div class="row ten-columns">
                        <div class="col-xs-6 col-sm-2">
                            <div class="benefits-box">
                                <div class="benefits-icon">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/logic-create.png" alt="Logic Create Image" />
                                </div>
                                <div class="benefits-txt">
                                    <p>Increased Logic & Concentration</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-2">
                            <div class="benefits-box">
                                <div class="benefits-icon">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/decision-making.png" alt="Decision Image" />
                                </div>
                                <div class="benefits-txt">
                                    <p>
                                        Create Great
                  Decision Makers
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-2">
                            <div class="benefits-box">
                                <div class="benefits-icon">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/solution.png" alt="Solution Image" />
                                </div>
                                <div class="benefits-txt">
                                    <p>Increased Problem Solving Skills</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-2">
                            <div class="benefits-box">
                                <div class="benefits-icon">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/planner.png" alt="Planner Image" />
                                </div>
                                <div class="benefits-txt">
                                    <p>
                                        Make Them a
                  Better Planner
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-2">
                            <div class="benefits-box">
                                <div class="benefits-icon">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/motivation.png" alt="Motivational Image" />
                                </div>
                                <div class="benefits-txt">
                                    <p>Coding Makes Self Motivated Kids</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Benefits Section -->
        <!-- Enterprenaul Section -->
        <!-- <%--<section class="enterprenaul">
            <div class="heading-txt">
                <h2>Get entrepreneur spirit high in your kids</h2>
            </div>
            <div class="enterprenaul-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 res-img1">
                            <div class="enterprenaul-img1">
                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/enterprenaul.png" alt="Enterpreneur Image" />
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="enterprenaul-txt">
                                <p>
                                    As per NASA research whatever you teach a child between the age of (6-14) as it’s the brain development
                age and the creativity and imagination level of the child is at its peak.
                                </p>
                                <p class="mt-20">Examples some of the most legendary coders are</p>
                                <ul>
                                    <li>Bill Gates started coding at the age of 13.</li>
                                    <li>Mark Zuckerberg at the age of 9. </li>
                                    <li>Elon musk started coding at the age of 10 and made a video game called blaster at the age of 12.
                                    </li>
                                    <li>Jan Kaum founder of WhatsApp started coding in high school.</li>
                                </ul>
                                <a href="../jh-form.aspx?sourceId=65" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta">BOOK A FREE CLASS</a>
                            </div>
                        </div>
                        <div class="col-md-5 res-img2">
                            <div class="enterprenaul-img2">
                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/enterprenaul.png" alt="Enterpreneur Image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>--%> -->
        <!-- End Enterprenaul Section -->

        
        <!-- Courses Section -->
        <section class="courses" id="courses">
            <div class="heading-txt">
                <h2>Our Courses</h2>
            </div>
            <div class="courses-container">
                <div class="container">
                    <div class="courses-tab-container">
                        <ul class="nav nav-pills nav-justified">
                            <li class="active course-bright" data-grade="1"><a data-toggle="pill" href="#class1t2">Class 1 to 2</a></li>
                            <li class="course-bright" data-grade="3"><a data-toggle="pill" href="#class3t4">Class 3 to 5</a></li>
                            <li class="course-bright" data-grade="5"><a data-toggle="pill" href="#class5t7">Class 6 to 8</a></li>
                            <li class="course-bright" data-grade="8"><a data-toggle="pill" href="#class8t10">Class 9 to 10</a></li>
                        </ul>
                        <div class="tab-content">
                            <div id="class1t2" class="tab-pane fade in active">
                                <p class="tab-head-txt">The Bright sparks</p>
                                <div class="oc-price-card">
                                    <div class="row " id="brightPieces1" style="display: none">
                                        <div class="col-md-4">
                                            <div class="ph-item">
                                                <div class="ph-col-12">
                                                    <div class="ph-picture"></div>
                                                    <div class="ph-row">
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 empty big"></div>
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 big"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-8 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                    </div>
                                                    <div class="ph-picture"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="ph-item">
                                                <div class="ph-col-12">
                                                    <div class="ph-picture"></div>
                                                    <div class="ph-row">
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 empty big"></div>
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 big"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-8 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                    </div>
                                                    <div class="ph-picture"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="ph-item">
                                                <div class="ph-col-12">
                                                    <div class="ph-picture"></div>
                                                    <div class="ph-row">
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 empty big"></div>
                                                        <div class="ph-col-4 big"></div>
                                                        <div class="ph-col-2 big"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-8 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-6 empty"></div>
                                                        <div class="ph-col-12"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                        <div class="ph-col-12 empty"></div>
                                                    </div>
                                                    <div class="ph-picture"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row row-flex" id="DataPieces1">
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance, Sequence Loops
                                                            Algorithm, Coding in Depth,
                                                            Variables, Sprite Lab, Greeting Card
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>

                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">Walk + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk phase ,Story Workshop, Musical Lab, Glide in Depth,Game
                            (Loops & Mouse Pointer),Maze Game,Car Game, Dance Battle Game
                            Chatbot,Calculator,Quiz, Revision, App Creation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>

                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">Jog + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including walk and jog phase,Facebook Login Page, Music Player, Calculator
                            Frontend, Map Making, Whatsapp Frontend,Visible And Invisible Components,Superhero
                            Frontend,Ludo Frontend,Mobile Dialpad,Google Search Engine Frontend,Photo Gallery Frontend
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization </p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="class3t4" class="tab-pane fade">
                                <p class="tab-head-txt">The Superstars</p>
                                <div class="row" id="brightPieces3" style="display: none">
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oc-price-card" id="DataPieces3">
                                    <div class="row row-flex">
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-supstar">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance,Sequence,Loops ,
                            Algorithm, Coding in Depth, Variables ,
                            Sprite Lab ,Animation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-supstar">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">Walk + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase Story Workshop, Musical Lab, Glide in Depth
                            Game (Loops & Mouse Pointer), Maze Game, Car Game
                            Dance Battle Game, Chatbot, Calculator, Quiz
                            Text to Speech App, Video Sensing Game, App Development
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-supstar">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">Jog + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including walk and jog phase,Instagram Login Page,Music Palyer,
                            Calculator Frontend, Map Making, Whatsapp Frontend,
                            Visible and Invisible Components,Superhero Frontend, Ludo/Chess Frontend
                            Mobile Dialpad , Google Search Engine Frontend,
                            Photo Gallery Frontend, Revision,Parent Interaction And Student Showcase
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-SpatialVisualization</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="class5t7" class="tab-pane fade">
                                <p class="tab-head-txt">The Masterminds</p>
                                <div class="row" id="brightPieces5" style="display: none">
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oc-price-card" id="DataPieces5">
                                    <div class="row row-flex">
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-masterminds">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance,Sequence,Loops
                            Algorithm, Coding in Depth, Variables
                            Sprite Lab, Greeting Card
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-masterminds">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">Walk + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase, Story Workshop, Musical Lab, Glide in Depth
                            Game (Loops & Mouse Pointer), Maze Game,
                            Car Game, Dance Battle Game,Chatbot, Calculator, Quiz
                            Revision,App Creation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-masterminds">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">Jog + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase And Jog Phase
                            Facebook Login Page,Music Player,Calculator Frontend,Map Making,
                            Whatsapp Frontend,Visible And Invisible Components, Superhero Frontend,
                            Ludo Frontend,Mobile Dialpad, Google Search Engine Frontend,
                            Photo Gallery Frontend, Class Assignment,
                            Parent Interaction and Student Showcase,Revision
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="class8t10" class="tab-pane fade">
                                <p class="tab-head-txt">The Genius Gems</p>
                                <div class="row" id="brightPieces8" style="display: none">
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="ph-item">
                                            <div class="ph-col-12">
                                                <div class="ph-picture"></div>
                                                <div class="ph-row">
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 empty big"></div>
                                                    <div class="ph-col-4 big"></div>
                                                    <div class="ph-col-2 big"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-8 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-6 empty"></div>
                                                    <div class="ph-col-12"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                    <div class="ph-col-12 empty"></div>
                                                </div>
                                                <div class="ph-picture"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="oc-price-card" id="DataPieces8">
                                    <div class="row row-flex">
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-genius-gems">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance + Variables, Loops, Algorithms
                            About Calculator, Arithmetic Operations,Logic
                            Conditional Statements, Components
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-genius-gems">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">Walk + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase
                            App Dev At Glance:Components, Login Page,
                            Label,Quiz Time, Button, About Text & Data,
                            Frontend Basics,Backend Basics, Introduction To Machine Learning,
                            About Face Detection, Innovate Maps,Web Development,
                            Video Sensing, Let's Design, Revise,Game Development
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="col-md-4">
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-genius-gems">
                                                <div class="oc-price-card-box content">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">Jog + 36 hours</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase And Jog Phase,
                            Training Model, Testing Model,Game Design,
                            Introduction To App, Math Utility, App Development,
                            App Design, Machine Learning,Wikipedia Page,
                            Al & ML, Let's Revise, Coding at Glance,
                            Chatbot, Animation,Game & Graphics.
                            Explore Sprite Lab, Project Time, PTM, Revise It All
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization </p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Courses Section -->
        <!-- Responsive Courses Section -->
        <section class="course-slide-section">
            <div class="heading-txt">
                <h2>Our Courses</h2>
            </div>
            <div class="courses-container">
                <div class="courses-tab-container">
                    <div class="container">
                        <ul class="nav nav-pills nav-justified">
                            <li class="active mobile-brightPiece" data-grade="1"><a data-toggle="pill" href="#rclass1t2">Class 1 to 2</a></li>
                            <li class="mobile-brightPiece" data-grade="3"><a data-toggle="pill" href="#rclass3t4">Class 3 to 5</a></li>
                            <li class="mobile-brightPiece" data-grade="5"><a data-toggle="pill" href="#rclass5t7">Class 6 to 8</a></li>
                            <li class="mobile-brightPiece" data-grade="8"><a data-toggle="pill" href="#rclass8t10">Class 9 to 10</a></li>
                        </ul>
                    </div>
                    <div class="container pr-0">
                        <div class="tab-content">
                            <div id="rclass1t2" class="tab-pane fade in active">
                                <p class="tab-head-txt">The Bright sparks</p>
                                <div class="oc-price-card">
                                    <div id="jh-1t2" class="owl-carousel owl-theme row-flex">
                                        <div class="item">
                                            <div class="row brightPiecesM1 ask-bright" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase">
                                                <div class="oc-price-card-box content DataPiecesM1">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance, Sequence Loops
                            Algorithm, Coding in Depth,
                            Variables, Sprite Lab, Greeting Card
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM1 ask-bright" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase">
                                                <div class="oc-price-card-box content DataPiecesM1">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">24 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk phase ,Story Workshop, Musical Lab, Glide in Depth,Game
                            (Loops & Mouse Pointer),Maze Game,Car Game, Dance Battle Game
                            Chatbot,Calculator,Quiz, Revision, App Creation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM1 ask-bright" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase">
                                                <div class="oc-price-card-box content DataPiecesM1">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">44 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including walk and jog phase,Facebook Login Page, Music Player, Calculator
                            Frontend, Map Making, Whatsapp Frontend,Visible And Invisible Components,Superhero
                            Frontend,Ludo Frontend,Mobile Dialpad,Google Search Engine Frontend,Photo Gallery Frontend
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization </p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="rclass3t4" class="tab-pane fade in">
                                <p class="tab-head-txt">The Superstars</p>
                                <div class="oc-price-card">
                                    <div id="jh-3t4" class="owl-carousel owl-theme">
                                        <div class="item">
                                            <div class="row brightPiecesM3" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-supstar">
                                                <div class="oc-price-card-box content DataPiecesM3">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance,Sequence,Loops ,
                            Algorithm, Coding in Depth, Variables ,
                            Sprite Lab ,Animation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM3" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-supstar">
                                                <div class="oc-price-card-box content DataPiecesM3">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">24 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase Story Workshop, Musical Lab, Glide in Depth
                            Game (Loops & Mouse Pointer), Maze Game, Car Game
                            Dance Battle Game, Chatbot, Calculator, Quiz
                            Text to Speech App, Video Sensing Game, App Development
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM3" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-supstar">
                                                <div class="oc-price-card-box content DataPiecesM3">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">44 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including walk and jog phase,Instagram Login Page,Music Palyer,
                            Calculator Frontend, Map Making, Whatsapp Frontend,
                            Visible and Invisible Components,Superhero Frontend, Ludo/Chess Frontend
                            Mobile Dialpad , Google Search Engine Frontend,
                            Photo Gallery Frontend, Revision,Parent Interaction And Student Showcase
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-SpatialVisualization</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="rclass5t7" class="tab-pane fade in">
                                <p class="tab-head-txt">The Masterminds</p>
                                <div class="oc-price-card">
                                    <div id="jh-5t7" class="owl-carousel owl-theme">
                                        <div class="item">
                                            <div class="row brightPiecesM5" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-masterminds">
                                                <div class="oc-price-card-box DataPiecesM5">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance,Sequence,Loops
                            Algorithm, Coding in Depth, Variables
                            Sprite Lab, Greeting Card
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM5" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-masterminds">
                                                <div class="oc-price-card-box DataPiecesM5">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">24 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase, Story Workshop, Musical Lab, Glide in Depth
                            Game (Loops & Mouse Pointer), Maze Game,
                            Car Game, Dance Battle Game,Chatbot, Calculator, Quiz
                            Revision,App Creation
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM5" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-masterminds">
                                                <div class="oc-price-card-box DataPiecesM5">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">44 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase And Jog Phase
                            Facebook Login Page,Music Player,Calculator Frontend,Map Making,
                            Whatsapp Frontend,Visible And Invisible Components, Superhero Frontend,
                            Ludo Frontend,Mobile Dialpad, Google Search Engine Frontend,
                            Photo Gallery Frontend, Class Assignment,
                            Parent Interaction and Student Showcase,Revision
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="rclass8t10" class="tab-pane fade in">
                                <p class="tab-head-txt">The Genius Gems</p>
                                <div class="oc-price-card">
                                    <div id="jh-8t10" class="owl-carousel owl-theme">
                                        <div class="item">
                                            <div class="row brightPiecesM8" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#walk-phase-genius-gems">
                                                <div class="oc-price-card-box DataPiecesM8">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-header.svg" alt="Walk Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Walk Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>14,160</p>
                                                            <p class="och-txt2">Or USD 160</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Beginner </span><span class="crs-grade-txt2">8 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Coding at Glance + Variables, Loops, Algorithms
                            About Calculator, Arithmetic Operations,Logic
                            Conditional Statements, Components
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Improvement in Logical Thinking</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/walk-footer.svg" alt="Walk Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM8" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#jog-phase-genius-gems">
                                                <div class="oc-price-card-box DataPiecesM8">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-header..svg" alt="Jog Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Jog Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>66,375</p>
                                                            <p class="och-txt2">Or USD 750</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Intermediate </span><span class="crs-grade-txt2">24 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase
                            App Dev At Glance:Components, Login Page,
                            Label,Quiz Time, Button, About Text & Data,
                            Frontend Basics,Backend Basics, Introduction To Machine Learning,
                            About Face Detection, Innovate Maps,Web Development,
                            Video Sensing, Let's Design, Revise,Game Development
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Full Entrepreneur Creative Expression</p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/jog-footer.svg" alt="Jog Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="item">
                                            <div class="row brightPiecesM8" style="display: none">
                                                <div class="col-md-4">
                                                    <div class="ph-item">
                                                        <div class="ph-col-12">
                                                            <div class="ph-picture"></div>
                                                            <div class="ph-row">
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 empty big"></div>
                                                                <div class="ph-col-4 big"></div>
                                                                <div class="ph-col-2 big"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-8 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-6 empty"></div>
                                                                <div class="ph-col-12"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                                <div class="ph-col-12 empty"></div>
                                                            </div>
                                                            <div class="ph-picture"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <a href="javascript:void()" data-toggle="modal" data-target="#run-phase-genius-gems">
                                                <div class="oc-price-card-box DataPiecesM8">
                                                    <div class="oc-head-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-header.svg" alt="Run Card Image" />
                                                        <div class="oc-head-content">
                                                            <p class="och-txt1">Run Phase</p>
                                                            <p class="price"><i class="fas fa-rupee-sign"></i>1,10,625</p>
                                                            <p class="och-txt2">Or USD 1,250</p>
                                                        </div>
                                                    </div>
                                                    <div class="oc-card-txt">
                                                        <p><span class="crs-grade-txt1">Advanced </span><span class="crs-grade-txt2">44 Hrs of classes</span></p>
                                                        <p class="occ-txt2">Curriculum and Activities</p>
                                                        <p class="occ-txt3">
                                                            Including Walk Phase And Jog Phase,
                            Training Model, Testing Model,Game Design,
                            Introduction To App, Math Utility, App Development,
                            App Design, Machine Learning,Wikipedia Page,
                            Al & ML, Let's Revise, Coding at Glance,
                            Chatbot, Animation,Game & Graphics.
                            Explore Sprite Lab, Project Time, PTM, Revise It All
                                                        </p>
                                                        <p class="occ-txt4">Benefits</p>
                                                        <p class="occ-txt5">Transformed Data-Spatial Visualization </p>
                                                    </div>
                                                    <div class="oc-footer-img">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/run-footer.svg" alt="Run Card Image" />
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Responsive Courses Section -->

        <!-- Courses Popup Section -->
        <div class="courses-popup-section">
            <div class="walk-phase-popup">

                <div id="walk-phase" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Bright sparks</p>
                                <p class="cmb-txt2">Beginner: The Walk Phase</p>
                                <p class="cmb-txt3">For classes 1 to 2</p>
                                <!-- <%--<p class="cmb-txt4">8 classes provided</p>--%> -->
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Improvement in Logical Thinking</p>
                                            <p class="cmb-lt5">₹ 14160</p>
                                            <p class="cmb-lt6">Or USD 160</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The walk phase is for beginners. In this, we make your child familiar with the
                                              basics of the course. The walk phase is specifically designed as a generic and the foundational
                                              level phase to introduce your child to many of the common concepts and the thought behind coding.
                                              In the first 8 hours of the course, we mainly focus on improving the basic fundamentals and
                                              developing the logical thinking skills of your child.
                                                                    </p>
                                                                    <p class="cmb-rt3">Curriculum and Activities</p>
                                                                    <p class="cmb-rt4">
                                                                        Logic building, Coding and Concepts,
                                              Puzzle Solving, Animation, Game Designing,
                                              App Designing
                                              & Front End design
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <span class="sp_back">Super Projects</span>
                                            <div class="lecture_details_left_box clearfix">
                                                <h2>Super Projects</h2>
                                                <div class="lecture_details_img">
                                                    <div class="ld_box">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/bright_sparks_img1.png" alt="Solution Image">
                                                        <div class="ld_img_text">
                                                            <span>Water Tracking</span>
                                                            <span>App</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/BRIGHT_SPARKS.pdf" target="_blank">View lecture details</a></div>
                                        </div>
                                    </div>
                                </div>



                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Beginner: The Walk Phase</p>
                                    <div class="panel-group" id="accordion2" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion2" href="#walk-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="walk-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance</td>
                                                                <td class="res-display-none">What is coding ? What are the steps to teach coding ? Explain with the help of logical
                                fun activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is the importance of a proper sequence of steps that needs to be followed to
                                perform the code.org activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">Explain the concept of Repetition and Looping with the help of activity from code.org?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Nested Loops</td>
                                                                <td class="res-display-none">What are the different types of loops and repetitions and how to use them in Scratch?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">Explain the problem solving approach with the help of an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Bug and Debug</td>
                                                                <td class="res-display-none">What are bugs? Also explain the concept of Debugging to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">What is PlayLab?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Play Lab Using Text Based Blocks</td>
                                                                <td class="res-display-none">Explain PlayLab to kids using the text-based blocks</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="jog-phase-popup">
                <div id="jog-phase" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Bright sparks</p>
                                <p class="cmb-txt2">Intermediate: The Jog Phase</p>
                                <p class="cmb-txt3">For classes 1 to 2</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Full Entrepreneur Creative Expression</p>
                                            <p class="cmb-lt5">₹ 66,375 00</p>
                                            <p class="cmb-lt6">Or USD 750</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The jog phase is the intermediate phase of the course that lets your child learn
                      and test the coding skills with exercises. After providing significant knowledge about the basics,
                      we introduce your child to the practical knowledge using all the fundamentals we’ve covered in the
                      Walk phase. In the jog phase, we dedicate 25 hours of the course to make your child learn how to
                      code using the programming language.
                                            </p>
                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk phase ,Story Workshop, Musical Lab, Glide in Depth,Game (Loops &
                      Mouse Pointer),Maze Game,Car Game, Dance Battle Game
                      Chatbot,Calculator,Quiz, Revision, App Creation
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <span class="sp_back">Super Projects</span>
                                            <div class="lecture_details_left_box clearfix">
                                                <h2>Super Projects</h2>
                                                <div class="lecture_details_img">
                                                    <div class="ld_box">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/bright_sparks_img2.png" alt="Solution Image">
                                                        <div class="ld_img_text">
                                                            <span>Space Shooter</span>
                                                            <span>Game</span>
                                                        </div>
                                                    </div>
                                                    <div class="ld_box">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/bright_sparks_img3.png" alt="Solution Image">
                                                        <div class="ld_img_text">
                                                            <span>Alarm Clock</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/BRIGHT_SPARKS.pdf" target="_blank">View lecture details</a></div>
                                        </div>
                                    </div>
                                </div>


                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Intermediate: The Jog Phase</p>
                                    <div class="panel-group" id="accordion3" role="tablist" aria-multiselectable="true">

                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion3" href="#walk-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="walk-phase11" class="panel-collapse collapse " role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance</td>
                                                                <td class="res-display-none">What is coding ? What are the steps to teach coding ? Explain with the help of logical
                                fun activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is the importance of a proper sequence of steps that needs to be followed to
                                perform the code.org activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">Explain the concept of Repetition and Looping with the help of activity from code.org?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Nested Loops</td>
                                                                <td class="res-display-none">What are the different types of loops and repetitions and how to use them in Scratch?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">Explain the problem solving approach with the help of an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Bug and Debug</td>
                                                                <td class="res-display-none">What are bugs? Also explain the concept of Debugging to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">What is PlayLab?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Play Lab Using Text Based Blocks</td>
                                                                <td class="res-display-none">Explain PlayLab to kids using the text-based blocks</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion3" href="#jog-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="jog-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Make, set, change, show and hide the variable</td>
                                                                <td class="res-display-none">What is Variable block in Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>More on Variables</td>
                                                                <td class="res-display-none">Explain more about the variables to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Animation</td>
                                                                <td class="res-display-none">Introduce animations to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Sprite Lab</td>
                                                                <td class="res-display-none">What is the X and Y axis in Scratch? Explain about the Turning Motion in Scratch. How
                                to change the costume of a sprite?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Flappy Bird Game</td>
                                                                <td class="res-display-none">How to develop a flappy bird game?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Greeting Card</td>
                                                                <td class="res-display-none">How to make a simple greeting card for family and friends?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Story Workshop</td>
                                                                <td class="res-display-none">Revise the previous topics and interact with parents</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Explain the application of sequencing and time control with the help of storytelling.
                                Use animal conversation stories to elaborate how to apply sequencing and time control.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Musical Lab</td>
                                                                <td class="res-display-none">How to add sound blocks and movements using the musical instruments?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Glide in Depth</td>
                                                                <td class="res-display-none">What is a Glide Block? Explain X and Y axis in detail using the butterfly analysis.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Game (Loops)</td>
                                                                <td class="res-display-none">Explain about Repeat Forever and</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="run-phase-popup">
                <div id="run-phase" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Bright sparks</p>
                                <p class="cmb-txt2">Advanced: The Run Phase</p>
                                <p class="cmb-txt3">For classes 1 to 2</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Transformed Data-Spatial Visualization</p>
                                            <p class="cmb-lt5">₹ 1,10,625</p>
                                            <p class="cmb-lt6">Or USD 1,250</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The run phase is the advanced phase of the course. In this phase, your child will
                      build projects using all the concepts that we’ve covered in the Walk and the Jog Phase. In the 40
                      hours of run phase, we provide your child with several opportunities to use his/her creativity to
                      showcase his/her coding skills. Besides, we focus on revising all the topics that we’ve taught to
                      your child in the overall course
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including walk and jog phase,Facebook Login Page, Music Player, Calculator
                      Frontend, Map Making, Whatsapp Frontend,Visible And Invisible Components,Superhero Frontend,Ludo
                      Frontend,Mobile Dialpad,Google Search Engine Frontend,Photo Gallery Frontend
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <span class="sp_back">Super Projects</span>
                                            <div class="lecture_details_left_box clearfix">
                                                <h2>Super Projects</h2>
                                                <div class="lecture_details_img">
                                                    <div class="ld_box">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/bright_sparks_img4.png" alt="Solution Image">
                                                        <div class="ld_img_text">
                                                            <span>Student Login</span>
                                                            <span>Registration</span>
                                                        </div>
                                                    </div>
                                                    <div class="ld_box">
                                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/bright_sparks_img5.png" alt="Solution Image">
                                                        <div class="ld_img_text">
                                                            <span>Cameleon</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/BRIGHT_SPARKS.pdf" target="_blank">View lecture details</a></div>
                                        </div>
                                    </div>
                                </div>

                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Advanced: The Run Phase</p>
                                    <div class="panel-group" id="accordion4" role="tablist" aria-multiselectable="true">

                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion4" href="#walk-phase111"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="walk-phase111" class="panel-collapse collapse " role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance</td>
                                                                <td class="res-display-none">What is coding ? What are the steps to teach coding ? Explain with the help of logical
                                fun activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is the importance of a proper sequence of steps that needs to be followed to
                                perform the code.org activity?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">Explain the concept of Repetition and Looping with the help of activity from code.org?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Nested Loops</td>
                                                                <td class="res-display-none">What are the different types of loops and repetitions and how to use them in Scratch?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">Explain the problem solving approach with the help of an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Bug and Debug</td>
                                                                <td class="res-display-none">What are bugs? Also explain the concept of Debugging to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">What is PlayLab?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Play Lab Using Text Based Blocks</td>
                                                                <td class="res-display-none">Explain PlayLab to kids using the text-based blocks</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion4" href="#jog-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="jog-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Make, set, change, show and hide the variable</td>
                                                                <td class="res-display-none">What is Variable block in Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>More on Variables</td>
                                                                <td class="res-display-none">Explain more about the variables to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Animation</td>
                                                                <td class="res-display-none">Introduce animations to kids.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Sprite Lab</td>
                                                                <td class="res-display-none">What is the X and Y axis in Scratch? Explain about the Turning Motion in Scratch. How
                                to change the costume of a sprite?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Flappy Bird Game</td>
                                                                <td class="res-display-none">How to develop a flappy bird game?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Greeting Card</td>
                                                                <td class="res-display-none">How to make a simple greeting card for family and friends?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Story Workshop</td>
                                                                <td class="res-display-none">Revise the previous topics and interact with parents</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Explain the application of sequencing and time control with the help of storytelling.
                                Use animal conversation stories to elaborate how to apply sequencing and time control.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Musical Lab</td>
                                                                <td class="res-display-none">How to add sound blocks and movements using the musical instruments?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Glide in Depth</td>
                                                                <td class="res-display-none">What is a Glide Block? Explain X and Y axis in detail using the butterfly analysis.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Game (Loops)</td>
                                                                <td class="res-display-none">Explain about Repeat Forever and</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion4" href="#run-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Advanced : The Run Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="run-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>App Creation</td>
                                                                <td class="res-display-none">What is an app ? How is it useful ? When did apps start? Explain how to change
                                background colour on apps by showing different apps.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Screen and Button</td>
                                                                <td class="res-display-none">What is a screen and a button? How one can customize them?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Pictures</td>
                                                                <td class="res-display-none">How to add picture/image?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Labels</td>
                                                                <td class="res-display-none">What is Label? How to customize a label?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Text Input</td>
                                                                <td class="res-display-none">What is Text Input and how can it be customized?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Revise the previous topics and interact with parents</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Creating Star war game</td>
                                                                <td class="res-display-none">How to develop a star war game?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Functions</td>
                                                                <td class="res-display-none">What are Functions? Why are they used?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>More on Functions</td>
                                                                <td class="res-display-none">What are the different types of functions? How to use functions? Explain with the help
                                of an example or activity</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Google Navigations</td>
                                                                <td class="res-display-none">What is Google Earth and Google Maps? Explain about Google Navigation.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>App+Animal Sound</td>
                                                                <td class="res-display-none">What is an App and how to add animal sound to it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>About Slider</td>
                                                                <td class="res-display-none">What are visible and invisible components? How these two are different? Explain
                                Sliders in detail.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>App (Functions)</td>
                                                                <td class="res-display-none">Show multiple apps on Playstore. Develop a ludo app on frontend.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Conditionals at Glance</td>
                                                                <td class="res-display-none">What are Conditionals? Why are conditionals important in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Conditionals in Depth</td>
                                                                <td class="res-display-none">Explain more about conditionals. Use examples and activity to teach conditionals in
                                depth.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Project time</td>
                                                                <td class="res-display-none">1. Make a map and add image, name and details to it in Scratch. 2. Make a Calculator
                                in the frontend. 3. Create a Google Search in frontend. 4. Create a dialpad similar to
                                the one in the mobile phones. 5. Make a graphic of your favourite superhero in the
                                frontend. 6 Create a photo gallery in the frontend.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>Parent Interaction and Student Showcase</td>
                                                                <td class="res-display-none">Revise the previous topics.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 18</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Introduce the list view to students and explain how to develop Whatsapp in frontend.
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="walk-phase-popup-superstar">
                <div id="walk-phase-supstar" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Superstars</p>
                                <p class="cmb-txt2">Beginner: The Walk Phase</p>
                                <p class="cmb-txt3">For classes 3 to 5</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Improvement in Logical Thinking</p>
                                            <p class="cmb-lt5">₹ 14,160</p>
                                            <p class="cmb-lt6">Or USD 160</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The walk phase is for beginners. In this, we make your child familiar with the
                      basics of the course. The walk phase is specifically designed as a generic and the foundational
                      level phase to introduce your child to many of the common concepts and the thought behind coding.
                      In the first 8 hours of the course, we mainly focus on improving the basic fundamentals and
                      developing the logical thinking skills of your child.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Coding at Glance,Sequence,Loops ,
                      Algorithm, Coding in Depth, Variables ,
                      Sprite Lab ,Animation
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/genius_gems_img1.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Pong Game</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/SUPERSTARS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>

                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Beginner: The Walk Phase</p>
                                    <div class="panel-group" id="accordion5" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion5" href="#suprstr-walk-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-walk-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding</td>
                                                                <td class="res-display-none">What is Coding? Explain the steps to code using a logical fun activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What are the Commands and Sequences? Explain the requirement of proper sequence of
                                steps with the help of code.org activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">How to write the algorithms? How to convert algorithms to coding using Blockly?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Bug & Debug</td>
                                                                <td class="res-display-none">What are Bugs? What is the meaning of Debugging? Explain with an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What is a Loop and its various types? Explain the use of Repeat Block.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">Introduce Play Lab theme of code.org using an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Play Lab using Text based blocks</td>
                                                                <td class="res-display-none">How to create a story using text-based blocks in PlayLab? Explain with an example.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Handling Events in Play Lab</td>
                                                                <td class="res-display-none">How to use events in Play Lab? How to apply all the coding skills to create an
                                animated game?</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="jog-phase-popup-superstar">
                <div id="jog-phase-supstar" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Superstars</p>
                                <p class="cmb-txt2">Intermediate: The Jog Phase</p>
                                <p class="cmb-txt3">For classes 3 to 5</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Full Entrepreneur Creative Expression</p>
                                            <p class="cmb-lt5">₹ 66,375</p>
                                            <p class="cmb-lt6">Or USD 750</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The jog phase is the intermediate phase of the course that lets your child learn
                      and test the coding skills with exercises. After providing significant knowledge about the basics,
                      we introduce your child to the practical knowledge using all the fundamentals we’ve covered in the
                      Walk phase. In the jog phase, we dedicate 25 hours of the course to make your child learn how to
                      code using the programming language.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk Phase Story Workshop, Musical Lab, Glide in Depth
                      Game (Loops & Mouse Pointer), Maze Game, Car Game
                      Dance Battle Game, Chatbot, Calculator, Quiz
                      Text to Speech App, Video Sensing Game, App Development
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/genius_gems_img2.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Student Login</span>
		                                                    <span>Analog Clock</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/genius_gems_img3.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Cameleon</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/SUPERSTARS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Intermediate: The Jog Phase</p>
                                    <div class="panel-group" id="accordion6" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion6" href="#suprstr-walk-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-walk-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding</td>
                                                                <td class="res-display-none">What is Coding? Explain the steps to code using a logical fun activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What are the Commands and Sequences? Explain the requirement of proper sequence of
                                steps with the help of code.org activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">How to write the algorithms? How to convert algorithms to coding using Blockly?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Bug & Debug</td>
                                                                <td class="res-display-none">What are Bugs? What is the meaning of Debugging? Explain with an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What is a Loop and its various types? Explain the use of Repeat Block.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">Introduce Play Lab theme of code.org using an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Play Lab using Text based blocks</td>
                                                                <td class="res-display-none">How to create a story using text-based blocks in PlayLab? Explain with an example.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Handling Events in Play Lab</td>
                                                                <td class="res-display-none">How to use events in Play Lab? How to apply all the coding skills to create an
                                animated game?</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion6" href="#suprstr-jog-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-jog-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Story Workshop</td>
                                                                <td class="res-display-none">How to create a story in Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Animation in Depth</td>
                                                                <td class="res-display-none">How to make interactive animated cartoons with changing backdrops?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Nested loops</td>
                                                                <td class="res-display-none">What is Nested Loop in coding and why is it used? Explain with the help of examples.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Glide in Depth</td>
                                                                <td class="res-display-none">Introduce Glide block, the turning motion and 2D coordinates (x-axis and y-axis) using
                                the Butterfly Analysis in Sprite Lab</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Sprite Lab</td>
                                                                <td class="res-display-none">Introduce Sprite Lab and its use? Add sound blocks and movements using musical
                                instruments.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Flappy Bird Game</td>
                                                                <td class="res-display-none">How to build a Flappy Bird Game in Sprite Lab?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Revise all the topics covered.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Game (Loops)</td>
                                                                <td class="res-display-none">Create a game using Repeat Loops like Repeat Forever and Repeat Until.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Game (Mouse+Graphics)</td>
                                                                <td class="res-display-none">How to create a clicker game using Touching, mouse down, and mouse pointer?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Maze Game</td>
                                                                <td class="res-display-none">How to make graphics for the maze game of a mouse and a cat ?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Dance Party</td>
                                                                <td class="res-display-none">How to build a Dance Party Game in Sprite Lab? How to add multiple costume changes and
                                time delays to it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Car Game</td>
                                                                <td class="res-display-none">Create a maze game using a cat and a mouse.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Until & While Loop</td>
                                                                <td class="res-display-none">Explain the use of Repeat Until and While Loops with the help of an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Calculator(Advanced)</td>
                                                                <td class="res-display-none">How to code to make a scientific or advanced calculator? Add different arithmetic
                                operators to the calculator.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Quiz</td>
                                                                <td class="res-display-none">Run a quiz on all the topics covered.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Variables</td>
                                                                <td class="res-display-none">What is a Variable? What are the different types of variables and why they are used?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>Text to Speech Google Assistant</td>
                                                                <td class="res-display-none">Create a chatbot similar to Google Assistant adding the text to speech feature.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="run-phase-popup-superstar">
                <div id="run-phase-supstar" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Superstars</p>
                                <p class="cmb-txt2">Advanced: The Run Phase</p>
                                <p class="cmb-txt3">For classes 3 to 5</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Transformed Data-Spatial Visualization </p>
                                            <p class="cmb-lt5">₹ 1,10,625</p>
                                            <p class="cmb-lt6">Or USD 1,250</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The run phase is the advanced phase of the course. In this phase, your child will
                      build projects using all the concepts that we’ve covered in the Walk and the Jog Phase. In the 40
                      hours of run phase, we provide your child with several opportunities to use his/her creativity to
                      showcase his/her coding skills. Besides, we focus on revising all the topics that we’ve taught to
                      your child in the overall course.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including walk and jog phase,Instagram Login Page,Music Palyer,
                      Calculator Frontend, Map Making, Whatsapp Frontend,
                      Visible and Invisible Components,Superhero Frontend, Ludo/Chess Frontend
                      Mobile Dialpad , Google Search Engine Frontend,
                      Photo Gallery Frontend, Revision,Parent Interaction And Student Showcase

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/genius_gems_img4.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Clown Nose game</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/genius_gems_img5.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Chat App</span>
		                                                    <span>Find The Outlier!</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/SUPERSTARS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Advanced: The Run Phase</p>
                                    <div class="panel-group" id="accordion7" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion7" href="#suprstr-walk-phase111"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-walk-phase111" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding</td>
                                                                <td class="res-display-none">What is Coding? Explain the steps to code using a logical fun activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What are the Commands and Sequences? Explain the requirement of proper sequence of
                                steps with the help of code.org activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Algorithm</td>
                                                                <td class="res-display-none">How to write the algorithms? How to convert algorithms to coding using Blockly?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Bug & Debug</td>
                                                                <td class="res-display-none">What are Bugs? What is the meaning of Debugging? Explain with an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What is a Loop and its various types? Explain the use of Repeat Block.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Introduction to Play Lab</td>
                                                                <td class="res-display-none">Introduce Play Lab theme of code.org using an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Play Lab using Text based blocks</td>
                                                                <td class="res-display-none">How to create a story using text-based blocks in PlayLab? Explain with an example.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Handling Events in Play Lab</td>
                                                                <td class="res-display-none">How to use events in Play Lab? How to apply all the coding skills to create an
                                animated game?</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion7" href="#suprstr-jog-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-jog-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Story Workshop</td>
                                                                <td class="res-display-none">How to create a story in Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Animation in Depth</td>
                                                                <td class="res-display-none">How to make interactive animated cartoons with changing backdrops?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Nested loops</td>
                                                                <td class="res-display-none">What is Nested Loop in coding and why is it used? Explain with the help of examples.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Glide in Depth</td>
                                                                <td class="res-display-none">Introduce Glide block, the turning motion and 2D coordinates (x-axis and y-axis) using
                                the Butterfly Analysis in Sprite Lab</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Sprite Lab</td>
                                                                <td class="res-display-none">Introduce Sprite Lab and its use? Add sound blocks and movements using musical
                                instruments.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Flappy Bird Game</td>
                                                                <td class="res-display-none">How to build a Flappy Bird Game in Sprite Lab?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Revise all the topics covered.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Game (Loops)</td>
                                                                <td class="res-display-none">Create a game using Repeat Loops like Repeat Forever and Repeat Until.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Game (Mouse+Graphics)</td>
                                                                <td class="res-display-none">How to create a clicker game using Touching, mouse down, and mouse pointer?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Maze Game</td>
                                                                <td class="res-display-none">How to make graphics for the maze game of a mouse and a cat ?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Dance Party</td>
                                                                <td class="res-display-none">How to build a Dance Party Game in Sprite Lab? How to add multiple costume changes and
                                time delays to it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Car Game</td>
                                                                <td class="res-display-none">Create a maze game using a cat and a mouse.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Until & While Loop</td>
                                                                <td class="res-display-none">Explain the use of Repeat Until and While Loops with the help of an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Calculator(Advanced)</td>
                                                                <td class="res-display-none">How to code to make a scientific or advanced calculator? Add different arithmetic
                                operators to the calculator.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Quiz</td>
                                                                <td class="res-display-none">Run a quiz on all the topics covered.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Variables</td>
                                                                <td class="res-display-none">What is a Variable? What are the different types of variables and why they are used?
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>Text to Speech Google Assistant</td>
                                                                <td class="res-display-none">Create a chatbot similar to Google Assistant adding the text to speech feature.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion7" href="#suprstr-run-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Advanced : The Run Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="suprstr-run-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Video Sensing Game</td>
                                                                <td class="res-display-none">How to build a video sensing game in a Game Lab environment? Develop the Soccer Game
                                with touch and play features.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>App Development</td>
                                                                <td class="res-display-none">Explain the concept of App Development with the help of an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Creating Star War Game</td>
                                                                <td class="res-display-none">How to code for building a Star War Game in Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Functions at Glance</td>
                                                                <td class="res-display-none">What is a Function? Why is it used?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Functions in Depth</td>
                                                                <td class="res-display-none">Explain the different types of functions and how to use functions with the help of an
                                example</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Dance Party (Functions)</td>
                                                                <td class="res-display-none">What functions are used in the Dance Party Game? Explain with the help of an example
                                or an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Revise all the topics covered. Organize a doubt solving session</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Minecraft Aquatic</td>
                                                                <td class="res-display-none">How to code in the Aquatic game mode on Minecraft?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Minecraft Adventures</td>
                                                                <td class="res-display-none">How to code in the Adventure game mode on Minecraft?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Minecraft Designer</td>
                                                                <td class="res-display-none">How to code in the Designer game mode on Minecraft?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Minecraft Hero's Journey</td>
                                                                <td class="res-display-none">How to code to create a Minecraft Hero's Journey?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>App+Animal Sound</td>
                                                                <td class="res-display-none">Explain App Development with example and add sound to it. Also add the feature of
                                color change on clicking a button</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>About Slider</td>
                                                                <td class="res-display-none">Explain the concept of visible and invisible components. How to make Sliders in
                                Scratch?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Google at Glance</td>
                                                                <td class="res-display-none">Explain how to create and customize Google's At a Glance Widget.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Google Earth and Web</td>
                                                                <td class="res-display-none">How to build Google Earth and Web apps?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Conditionals at Glance</td>
                                                                <td class="res-display-none">What are Conditionals? Explain different types of conditional statements used in
                                coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>Conditionals in Depth</td>
                                                                <td class="res-display-none">How to use conditional statements? Explain with the help of an example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 18</td>
                                                                <td>Parent Interaction And Student Showcase</td>
                                                                <td class="res-display-none">Revise all the topics covered. Organize a doubt solving session.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 19</td>
                                                                <td>Revision</td>
                                                                <td class="res-display-none">Ask students to present the projects. Also organize a parent-teacher meeting to
                                discuss the performance of the student.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="walk-phase-popup-masterminds">
                <div id="walk-phase-masterminds" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Masterminds</p>
                                <p class="cmb-txt2">Beginner: The Walk Phase</p>
                                <p class="cmb-txt3">For classes 6 to 8</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Improvement in Logical Thinking</p>
                                            <p class="cmb-lt5">₹ 14,160</p>
                                            <p class="cmb-lt6">Or USD 160</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The walk phase is for beginners. In this, we make your child familiar with the
                      basics of the course. The walk phase is specifically designed as a generic and the foundational
                      level phase to introduce your child to many of the common concepts and the thought behind coding.
                      In the first 8 hours of the course, we mainly focus on improving the basic fundamentals and
                      developing the logical thinking skills of your child.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Coding at Glance,Sequence,Loops
                      Algorithm, Coding in Depth, Variables
                      Sprite Lab, Greeting Card
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/master_minds_img1.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Super Sixes Game</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/MASTERMINDS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Beginner: The Walk Phase</p>
                                    <div class="panel-group" id="accordion8" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion8" href="#master-walk-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-walk-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>An Introduction to Coding</td>
                                                                <td class="res-display-none">What is Coding? What are the steps to code ? Explain with the help of a logical fun
                                activity on Variables, Loops and Conditional Statements using Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>What is Complex motion?</td>
                                                                <td class="res-display-none">Explain how Complex Motion works in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Arithmetic Operators</td>
                                                                <td class="res-display-none">Explain the Arithmetic Operator. Create a calculator using only the addition operation
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Building a Guess Game</td>
                                                                <td class="res-display-none">Build a guess game in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Functions in Coding</td>
                                                                <td class="res-display-none">What is a Function? .What are its types and how to use Functions in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Loops ( for, while)</td>
                                                                <td class="res-display-none">What are for and while loops? How to use these loops in coding? Explain with an
                                example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Loops (do while)</td>
                                                                <td class="res-display-none">What do and while loops? How to use these loops in coding? Explain with an example
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Game Mechanics in Python</td>
                                                                <td class="res-display-none">How to develop a game in Python? Explain with the help of developing a car racing game
                                using Variables and Complex Sprite Motions.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="jog-phase-popup-masterminds">
                <div id="jog-phase-masterminds" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Masterminds</p>
                                <p class="cmb-txt2">Intermediate: The Jog Phase</p>
                                <p class="cmb-txt3">For classes 6 to 8</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Full Entrepreneur Creative Expression</p>
                                            <p class="cmb-lt5">₹ 66,375</p>
                                            <p class="cmb-lt6">Or USD 750</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The jog phase is the intermediate phase of the course that lets your child learn
                      and test the coding skills with exercises. After providing significant knowledge about the basics,
                      we introduce your child to the practical knowledge using all the fundamentals we’ve covered in the
                      Walk phase. In the jog phase, we dedicate 25 hours of the course to make your child learn how to
                      code using the programming language.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk Phase, Story Workshop, Musical Lab, Glide in Depth
                      Game (Loops & Mouse Pointer), Maze Game,
                      Car Game, Dance Battle Game,Chatbot, Calculator, Quiz
                      Revision,App Creation
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/master_minds_img2.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Clown Nose game</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/master_minds_img3.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Mini Golf Game</span>
		                                                    <span>Catch The Ball</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/MASTERMINDS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>

                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Intermediate: The Jog Phase</p>
                                    <div class="panel-group" id="accordion9" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion9" href="#master-walk-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-walk-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>An Introduction to Coding</td>
                                                                <td class="res-display-none">What is Coding? What are the steps to code ? Explain with the help of a logical fun
                                activity on Variables, Loops and Conditional Statements using Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>What is Complex motion?</td>
                                                                <td class="res-display-none">Explain how Complex Motion works in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Arithmetic Operators</td>
                                                                <td class="res-display-none">Explain the Arithmetic Operator. Create a calculator using only the addition operation
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Building a Guess Game</td>
                                                                <td class="res-display-none">Build a guess game in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Functions in Coding</td>
                                                                <td class="res-display-none">What is a Function? .What are its types and how to use Functions in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Loops ( for, while)</td>
                                                                <td class="res-display-none">What are for and while loops? How to use these loops in coding? Explain with an
                                example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Loops (do while)</td>
                                                                <td class="res-display-none">What do and while loops? How to use these loops in coding? Explain with an example
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Game Mechanics in Python</td>
                                                                <td class="res-display-none">How to develop a game in Python? Explain with the help of developing a car racing game
                                using Variables and Complex Sprite Motions.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion9" href="#master-jog-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-jog-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Quiz using Python</td>
                                                                <td class="res-display-none">Run a quiz on Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Introduction to Recommenders</td>
                                                                <td class="res-display-none">What are Recommenders in Python? Why are they useful?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>More About Recommenders</td>
                                                                <td class="res-display-none">Create a simple recommender system using Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Introduction to Chatbot</td>
                                                                <td class="res-display-none">What is a Chatbot? Create a chatbot and add text to speech feature like Google
                                Assistant/ Talking to Scratch Animation using ML platforms.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Building a Chatbot</td>
                                                                <td class="res-display-none">How to build and implement a chatbot in a website or an application?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Dance Party (Functions)</td>
                                                                <td class="res-display-none">What functions are used in the Dance Party Game? Explain with the help of an example
                                or an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>App Development at Glance</td>
                                                                <td class="res-display-none">What is App Development? How to create a simple Music Player app like Spotify or
                                YTMusic.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Buttons</td>
                                                                <td class="res-display-none">What are Buttons? How to code to create a button?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Labels</td>
                                                                <td class="res-display-none">What is a Label? Why is it used? How to code to create a label and how to customize
                                it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Text fields</td>
                                                                <td class="res-display-none">How to add Text Fields to a webpage? Explain with the help of coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Frontend</td>
                                                                <td class="res-display-none">Make a Calculator on the frontend and implement the addition, subtraction,
                                multiplication, and division operation to it</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Backend</td>
                                                                <td class="res-display-none">Develop a website on Backend using Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Web Dev at glance</td>
                                                                <td class="res-display-none">What is Web Development? Create a favourite movie webpage.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Building a Wikipedia Page</td>
                                                                <td class="res-display-none">How to create a simple Wikipedia Page on web?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Building the Google Search Engine</td>
                                                                <td class="res-display-none">Create a Google Search Engine homepage using hyperlink, button, and image tags.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Building a Book My Show Page</td>
                                                                <td class="res-display-none">Create a favourite movie webpage similar to BookmyShow</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>To do list</td>
                                                                <td class="res-display-none">Create a simple Instagram Login Page using image, button, label, and text input.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 18</td>
                                                                <td>Resume Page Creation</td>
                                                                <td class="res-display-none">How to code to create a Resume Page on web?</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="run-phase-popup-masterminds">
                <div id="run-phase-masterminds" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Masterminds</p>
                                <p class="cmb-txt2">Advanced: The Run Phase</p>
                                <p class="cmb-txt3">For classes 6 to 8</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Transformed Data-Spatial Visualization </p>
                                            <p class="cmb-lt5">₹ 1,10,625</p>
                                            <p class="cmb-lt6">Or USD 1,250</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The run phase is the advanced phase of the course. In this phase, your child will
                      build projects using all the concepts that we’ve covered in the Walk and the Jog Phase. In the 40
                      hours of run phase, we provide your child with several opportunities to use his/her creativity to
                      showcase his/her coding skills. Besides, we focus on revising all the topics that we’ve taught to
                      your child in the overall course.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk Phase And Jog Phase
                      Facebook Login Page,Music Player,Calculator Frontend,Map Making,
                      Whatsapp Frontend,Visible And Invisible Components, Superhero Frontend,
                      Ludo Frontend,Mobile Dialpad, Google Search Engine Frontend,
                      Photo Gallery Frontend, Class Assignment,
                      Parent Interaction and Student Showcase,Revision

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/master_minds_img4.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Clown Nose game</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/master_minds_img5.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Shooting Game-2D</span>
		                                                    <span>Mini golf-2D</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/MASTERMINDS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>

                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Advanced: The Run Phase</p>
                                    <div class="panel-group" id="accordion10" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion10" href="#master-walk-phase111"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-walk-phase111" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>An Introduction to Coding</td>
                                                                <td class="res-display-none">What is Coding? What are the steps to code ? Explain with the help of a logical fun
                                activity on Variables, Loops and Conditional Statements using Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>What is Complex motion?</td>
                                                                <td class="res-display-none">Explain how Complex Motion works in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Arithmetic Operators</td>
                                                                <td class="res-display-none">Explain the Arithmetic Operator. Create a calculator using only the addition operation
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Building a Guess Game</td>
                                                                <td class="res-display-none">Build a guess game in Scratch.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Functions in Coding</td>
                                                                <td class="res-display-none">What is a Function? .What are its types and how to use Functions in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Loops ( for, while)</td>
                                                                <td class="res-display-none">What are for and while loops? How to use these loops in coding? Explain with an
                                example.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Loops (do while)</td>
                                                                <td class="res-display-none">What do and while loops? How to use these loops in coding? Explain with an example
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Game Mechanics in Python</td>
                                                                <td class="res-display-none">How to develop a game in Python? Explain with the help of developing a car racing game
                                using Variables and Complex Sprite Motions.</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion10" href="#master-jog-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-jog-phase11" class="panel-collapse collapse " role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Quiz using Python</td>
                                                                <td class="res-display-none">Run a quiz on Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Introduction to Recommenders</td>
                                                                <td class="res-display-none">What are Recommenders in Python? Why are they useful?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>More About Recommenders</td>
                                                                <td class="res-display-none">Create a simple recommender system using Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Introduction to Chatbot</td>
                                                                <td class="res-display-none">What is a Chatbot? Create a chatbot and add text to speech feature like Google
                                Assistant/ Talking to Scratch Animation using ML platforms.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Building a Chatbot</td>
                                                                <td class="res-display-none">How to build and implement a chatbot in a website or an application?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Dance Party (Functions)</td>
                                                                <td class="res-display-none">What functions are used in the Dance Party Game? Explain with the help of an example
                                or an activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>App Development at Glance</td>
                                                                <td class="res-display-none">What is App Development? How to create a simple Music Player app like Spotify or
                                YTMusic.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Buttons</td>
                                                                <td class="res-display-none">What are Buttons? How to code to create a button?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Labels</td>
                                                                <td class="res-display-none">What is a Label? Why is it used? How to code to create a label and how to customize
                                it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Text fields</td>
                                                                <td class="res-display-none">How to add Text Fields to a webpage? Explain with the help of coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Frontend</td>
                                                                <td class="res-display-none">Make a Calculator on the frontend and implement the addition, subtraction,
                                multiplication, and division operation to it</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Backend</td>
                                                                <td class="res-display-none">Develop a website on Backend using Python.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Web Dev at glance</td>
                                                                <td class="res-display-none">What is Web Development? Create a favourite movie webpage.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Building a Wikipedia Page</td>
                                                                <td class="res-display-none">How to create a simple Wikipedia Page on web?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Building the Google Search Engine</td>
                                                                <td class="res-display-none">Create a Google Search Engine homepage using hyperlink, button, and image tags.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Building a Book My Show Page</td>
                                                                <td class="res-display-none">Create a favourite movie webpage similar to BookmyShow</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 17</td>
                                                                <td>To do list</td>
                                                                <td class="res-display-none">Create a simple Instagram Login Page using image, button, label, and text input.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 18</td>
                                                                <td>Resume Page Creation</td>
                                                                <td class="res-display-none">How to code to create a Resume Page on web?</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion10" href="#master-run-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Advanced : The Run Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="master-run-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Education Site Development</td>
                                                                <td class="res-display-none">Develop an education website</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Introduction to CSS</td>
                                                                <td class="res-display-none">What is CSS? Why is it important? Explain different types of CSS.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>JS - Introduction to JavaScript</td>
                                                                <td class="res-display-none">What is JS? What does it do and what is it used for?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>ML and AI at glance</td>
                                                                <td class="res-display-none">Discuss Machine Learning and Artificial Intelligence. Explain Test and Training Model
                                and create a Stone-Paper-Scissor Gane using this model.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>ML - text data</td>
                                                                <td class="res-display-none">What is text data in Machine Learning and how to encode it?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>ML - image data</td>
                                                                <td class="res-display-none">What is image data in Machine Learning and how to label it? Explain how to prepare it
                                using Training Models in ML.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>ML - number data</td>
                                                                <td class="res-display-none">What is number data? How to prepare it for a train and test model in ML? How to add it
                                in an app?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Development of Recommender System</td>
                                                                <td class="res-display-none">Create a Book My Show Page and add the recommender system to it.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Create Self Driving Cars in Scratch</td>
                                                                <td class="res-display-none">Design self driving cars using all the concepts that are already covered.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Introduction to NLP - Natural Language Processing</td>
                                                                <td class="res-display-none">Introduce NLP in detail. Create Sentiment Analyzer on Scratch using the Train and Test
                                Model of Machine Learning.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Advanced Python</td>
                                                                <td class="res-display-none">Explain Python in detail.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Advanced JS</td>
                                                                <td class="res-display-none">Explain JS in detail.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Concepts of Dialog flow</td>
                                                                <td class="res-display-none">What is Dialog flow? How to build a Chatbot with Dialog Flow?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Teachable machine</td>
                                                                <td class="res-display-none">What is a Teachable Machine? Explain how to teach your computer to recognize your
                                images, sounds, and pos</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>PTM</td>
                                                                <td class="res-display-none">Organize a Parent-Teacher meeting to discuss the performance of the student and ask
                                students to present the projects.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="walk-phase-popup-genius-gems">
                <div id="walk-phase-genius-gems" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Genius Gems</p>
                                <p class="cmb-txt2">Beginner: The Walk Phase</p>
                                <p class="cmb-txt3">For classes 9 to 10</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Improvement in Logical Thinking </p>
                                            <p class="cmb-lt5">₹ 14,160</p>
                                            <p class="cmb-lt6">Or USD 160</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The walk phase is for beginners. In this, we make your child familiar with the
                      basics of the course. The walk phase is specifically designed as a generic and the foundational
                      level phase to introduce your child to many of the common concepts and the thought behind coding.
                      In the first 8 hours of the course, we mainly focus on improving the basic fundamentals and
                      developing the logical thinking skills of your child.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Coding at Glance + Variables, Loops, Algorithms
                      About Calculator, Arithmetic Operations,Logic
                      Conditional Statements, Components
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/super_stars_img1.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Flappy Bird Game</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/GENIUS_GEMS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Beginner: The Walk Phase</p>
                                    <div class="panel-group" id="accordion11" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion11" href="#genius-walk-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-walk-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance and Introduction to Variables</td>
                                                                <td class="res-display-none">Explain the concept of Coding . What are Variables?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Algorithms & Flowcharts</td>
                                                                <td class="res-display-none">What are Algorithms? What are the uses of Algorithms? Explain various problem solving
                                techniques.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is Sequence? Explain the role of sequence in coding</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What are Loops? What are the different types of Loops?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Conditional Statements</td>
                                                                <td class="res-display-none">What are Conditional Statements? Explain different types of Conditional Statements
                                used in coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Arrays</td>
                                                                <td class="res-display-none">What is an Array in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Functions</td>
                                                                <td class="res-display-none">What is a Function and why do we use it? How many types of functions are there in
                                coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>More About Functions</td>
                                                                <td class="res-display-none">How to use Functions in coding? Explain with an example</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="jog-phase-popup-genius-gems">
                <div id="jog-phase-genius-gems" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Genius Gems</p>
                                <p class="cmb-txt2">Intermediate: The Jog Phase</p>
                                <p class="cmb-txt3">For classes 9 to 10</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Full Entrepreneur Creative Expression</p>
                                            <p class="cmb-lt5">₹ 66,375</p>
                                            <p class="cmb-lt6">Or USD 750</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The jog phase is the intermediate phase of the course that lets your child learn
                      and test the coding skills with exercises. After providing significant knowledge about the basics,
                      we introduce your child to the practical knowledge using all the fundamentals we’ve covered in the
                      Walk phase. In the jog phase, we dedicate 25 hours of the course to make your child learn how to
                      code using the programming language.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk Phase
                      App Dev At Glance:Components, Login Page,
                      Label,Quiz Time, Button, About Text & Data,
                      Frontend Basics,Backend Basics, Introduction To Machine Learning,
                      About Face Detection, Innovate Maps,Web Development,
                      Video Sensing, Let's Design, Revise,Game Development

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/super_stars_img2.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Catch and Eat<br/>Burger Game</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/super_stars_img3.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Car Racing Game</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/GENIUS_GEMS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Intermediate: The Jog Phase</p>
                                    <div class="panel-group" id="accordion12" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion12" href="#genius-walk-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-walk-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance and Introduction to Variables</td>
                                                                <td class="res-display-none">Explain the concept of Coding . What are Variables?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Algorithms & Flowcharts</td>
                                                                <td class="res-display-none">What are Algorithms? What are the uses of Algorithms? Explain various problem solving
                                techniques.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is Sequence? Explain the role of sequence in coding</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What are Loops? What are the different types of Loops?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Conditional Statements</td>
                                                                <td class="res-display-none">What are Conditional Statements? Explain different types of Conditional Statements
                                used in coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Arrays</td>
                                                                <td class="res-display-none">What is an Array in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Functions</td>
                                                                <td class="res-display-none">What is a Function and why do we use it? How many types of functions are there in
                                coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>More About Functions</td>
                                                                <td class="res-display-none">How to use Functions in coding? Explain with an example</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion12" href="#genius-jog-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-jog-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Quiz Time - Revise It All</td>
                                                                <td class="res-display-none">Revise all the topics that are already covered. Run a quiz on any fun topic or
                                introduce Variables. Example: SCORE</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Bug & Debug</td>
                                                                <td class="res-display-none">What is Bug and Debug? How are these two different? Explain with the help of examples
                                and activities.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Arithmetic Operations</td>
                                                                <td class="res-display-none">What are the Operators in Coding? Explain Arithmetic Operations with the help of
                                Multiplication Tables activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>About Calculator</td>
                                                                <td class="res-display-none">How to create a Scientific Calculator with coding? Make a calculator with the backend
                                and various arithmetic operations.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Math With Python</td>
                                                                <td class="res-display-none">Explain all the mathematical functions available in Python and how you can use them in
                                coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Encoding and Decoding Numbers</td>
                                                                <td class="res-display-none">What is Encoding and Decoding of Numbers in programming? Explain with the help of
                                examples.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Attributes</td>
                                                                <td class="res-display-none">What is an Attribute in coding? Give a few examples of attributes.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Objects</td>
                                                                <td class="res-display-none">What is an Object in Coding? What are the different types of objects used in Coding
                                and why they are used?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Object-oriented Design</td>
                                                                <td class="res-display-none">What is Object-Oriented Design? Explain taking a few examples.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Design with HTML</td>
                                                                <td class="res-display-none">How to create design in HTML? Take a suitable example to explain. Use buttons and
                                other tags while creating a design.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Artist Lab - JavaScript</td>
                                                                <td class="res-display-none">How to make a map with JavaScript? How to code for adding an image, name, and other
                                details? </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Logic and if Statements</td>
                                                                <td class="res-display-none">Explain various logics and if statements used in coding with the help of examples</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Resizing of JavaScript with Variable expressions</td>
                                                                <td class="res-display-none">Take the different shapes and teach students to resize it by changing the value of
                                variables.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Improving Programming Skills</td>
                                                                <td class="res-display-none">Improve the programming skills of students by asking them to develop a video sensing
                                game - Catch the Insect.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Game & Graphics</td>
                                                                <td class="res-display-none">How to develop a game? Design by adding screen, button, sound, an image in background
                                and inside the button.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Animations</td>
                                                                <td class="res-display-none">What is an Animation? How to create an animation and add image, sound, and text?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Installation of IDE and Introduction to Text-Based Coding</td>
                                                                <td class="res-display-none">What is IDE and how to install it? What is Text Input, its type and how to consume it?
                                Explain Text-based Coding.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="run-phase-popup-genius-gems">
                <div id="run-phase-genius-gems" class="modal fade" role="dialog">
                    <div class="modal-dialog modal-lg">
                        <!-- Modal content-->
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal">
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p class="cmb-txt1">Genius Gems</p>
                                <p class="cmb-txt2">Advanced: The Run Phase</p>
                                <p class="cmb-txt3">For classes 9 to 10</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="cmb-left-txt">
                                            <p class="cmb-lt3">Benefits</p>
                                            <p class="cmb-lt4">Transformed Data-SpatialVisualization</p>
                                            <p class="cmb-lt5">₹ 1,10,625</p>
                                            <p class="cmb-lt6">Or USD 1,250</p>
                                            <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="cmb-cta tempUrl">BOOK A FREE CLASS</a>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="cmb-right-txt">
                                            <p class="cmb-rt1">About the course</p>
                                            <p class="cmb-rt2">
                                                The run phase is the advanced phase of the course. In this phase, your child will
                      build projects using all the concepts that we’ve covered in the Walk and the Jog Phase. In the 40
                      hours of run phase, we provide your child with several opportunities to use his/her creativity to
                      showcase his/her coding skills. Besides, we focus on revising all the topics that we’ve taught to
                      your child in the overall course.
                                            </p>

                                            <p class="cmb-rt3">Curriculum and Activities</p>
                                            <p class="cmb-rt4">
                                                Including Walk Phase And Jog Phase,
                      Training Model, Testing Model,Game Design,
                      Introduction To App, Math Utility, App Development,
                      App Design, Machine Learning,Wikipedia Page,
                      Al & ML, Let's Revise, Coding at Glance,
                      Chatbot, Animation,Game & Graphics.
                      Explore Sprite Lab, Project Time, PTM, Revise It All

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="lecture_details_box">
	                                <div class="row">
		                                <div class="col-md-9">
		                                    <span class="sp_back">Super Projects</span>
		                                    <div class="lecture_details_left_box clearfix">
		                                        <h2>Super Projects</h2>
		                                        <div class="lecture_details_img">
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/super_stars_img4.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Web Page creation</span>
		                                                </div>
		                                            </div>
		                                            <div class="ld_box">
		                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/super_stars_img5.png" alt="Solution Image">
		                                                <div class="ld_img_text">
		                                                    <span>Simple Calculator</span>
		                                                    <span>Fruit Ninja Game</span>
		                                                </div>
		                                            </div>
		                                        </div>
		                                    </div>
		                                </div>
		                                <div class="col-md-3">
		                                    <div class="lecture_details_btn"><a href="https://files.askiitians.com/static_content/Resources/images/jh-images/GENIUS_GEMS.pdf" target="_blank">View lecture details</a></div>
		                                </div>
	                                </div>
                                </div>
                                <div class="collapse-txt-container">
                                    <p class="ctc-head-txt">Lecture for Advanced: The Run Phase</p>
                                    <div class="panel-group" id="accordion13" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion13" href="#genius-walk-phase111"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Beginner : The Walk Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-walk-phase111" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Coding at Glance and Introduction to Variables</td>
                                                                <td class="res-display-none">Explain the concept of Coding . What are Variables?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Algorithms & Flowcharts</td>
                                                                <td class="res-display-none">What are Algorithms? What are the uses of Algorithms? Explain various problem solving
                                techniques.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Sequence</td>
                                                                <td class="res-display-none">What is Sequence? Explain the role of sequence in coding</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Loops</td>
                                                                <td class="res-display-none">What are Loops? What are the different types of Loops?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Conditional Statements</td>
                                                                <td class="res-display-none">What are Conditional Statements? Explain different types of Conditional Statements
                                used in coding.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Arrays</td>
                                                                <td class="res-display-none">What is an Array in coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Functions</td>
                                                                <td class="res-display-none">What is a Function and why do we use it? How many types of functions are there in
                                coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>More About Functions</td>
                                                                <td class="res-display-none">How to use Functions in coding? Explain with an example</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading " role="tab">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion13" href="#genius-jog-phase11"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Intermediate : The Jog Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-jog-phase11" class="panel-collapse collapse" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>Quiz Time - Revise It All</td>
                                                                <td class="res-display-none">Revise all the topics that are already covered. Run a quiz on any fun topic or
                                introduce Variables. Example: SCORE</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Bug & Debug</td>
                                                                <td class="res-display-none">What is Bug and Debug? How are these two different? Explain with the help of examples
                                and activities.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Arithmetic Operations</td>
                                                                <td class="res-display-none">What are the Operators in Coding? Explain Arithmetic Operations with the help of
                                Multiplication Tables activity.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>About Calculator</td>
                                                                <td class="res-display-none">How to create a Scientific Calculator with coding? Make a calculator with the backend
                                and various arithmetic operations.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Math With Python</td>
                                                                <td class="res-display-none">Explain all the mathematical functions available in Python and how you can use them in
                                coding?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Encoding and Decoding Numbers</td>
                                                                <td class="res-display-none">What is Encoding and Decoding of Numbers in programming? Explain with the help of
                                examples.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Attributes</td>
                                                                <td class="res-display-none">What is an Attribute in coding? Give a few examples of attributes.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Objects</td>
                                                                <td class="res-display-none">What is an Object in Coding? What are the different types of objects used in Coding
                                and why they are used?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Object-oriented Design</td>
                                                                <td class="res-display-none">What is Object-Oriented Design? Explain taking a few examples.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Design with HTML</td>
                                                                <td class="res-display-none">How to create design in HTML? Take a suitable example to explain. Use buttons and
                                other tags while creating a design.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>Artist Lab - JavaScript</td>
                                                                <td class="res-display-none">How to make a map with JavaScript? How to code for adding an image, name, and other
                                details? </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Logic and if Statements</td>
                                                                <td class="res-display-none">Explain various logics and if statements used in coding with the help of examples</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Resizing of JavaScript with Variable expressions</td>
                                                                <td class="res-display-none">Take the different shapes and teach students to resize it by changing the value of
                                variables.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Improving Programming Skills</td>
                                                                <td class="res-display-none">Improve the programming skills of students by asking them to develop a video sensing
                                game - Catch the Insect.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Game & Graphics</td>
                                                                <td class="res-display-none">How to develop a game? Design by adding screen, button, sound, an image in background
                                and inside the button.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 16</td>
                                                                <td>Animations</td>
                                                                <td class="res-display-none">What is an Animation? How to create an animation and add image, sound, and text?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Installation of IDE and Introduction to Text-Based Coding</td>
                                                                <td class="res-display-none">What is IDE and how to install it? What is Text Input, its type and how to consume it?
                                Explain Text-based Coding.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="panel panel-default">
                                            <div class="panel-heading active" role="tab" id="headingOne">
                                                <h4 class="panel-title">
                                                    <a role="button" data-toggle="collapse" data-parent="#accordion13" href="#genius-run-phase1"
                                                        aria-expanded="true" aria-controls="collapseOne">Course Details for the Advanced : The Run Phase
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="genius-run-phase1" class="panel-collapse collapse in" role="tabpanel"
                                                aria-labelledby="headingOne">
                                                <div class="panel-body">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th class="th1">Lecture No.</th>
                                                                <th class="th2">Lecture Name</th>
                                                                <th class="res-display-none">Building Skills</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Lecture 1</td>
                                                                <td>App Development</td>
                                                                <td class="res-display-none">What is App Development? Develop a Game Application using code.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 2</td>
                                                                <td>Components of App Development</td>
                                                                <td class="res-display-none">What are the various components that are necessary to develop an application?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 3</td>
                                                                <td>Frontend & Backend Basics</td>
                                                                <td class="res-display-none">What is the frontend and backend development? Create the frontend and backend of
                                Whatsapp as much as possible.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 4</td>
                                                                <td>Artificial Intelligence & Machine Learning</td>
                                                                <td class="res-display-none">What is Artificial Intelligence ? Briefly explain the concept of Machine Learning.
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 5</td>
                                                                <td>Introduction To Machine Learning</td>
                                                                <td class="res-display-none">Explain Machine Learning with examples. Create the Stone-Paper-Scissor game using the
                                Image Training Model</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 6</td>
                                                                <td>Face Detection</td>
                                                                <td class="res-display-none">How does Face Detection work? Explain with the help of an activity on Google.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 7</td>
                                                                <td>Web Development</td>
                                                                <td class="res-display-none">What is Web Development? Show different websites to explain in detail.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 8</td>
                                                                <td>Login Page</td>
                                                                <td class="res-display-none">How to code for a Login Page? Explain with an example. Use buttons, labels and text
                                inputs to create a login page.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 9</td>
                                                                <td>Buttons & Labels</td>
                                                                <td class="res-display-none">What are the Buttons and Labels in Coding? How to style and customize the buttons and
                                labels?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 10</td>
                                                                <td>Templates</td>
                                                                <td class="res-display-none">What is Artificial Intelligence (AI) and Machine Learning (ML)? What are the uses of
                                AI and ML</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 11</td>
                                                                <td>AGoogle Homepage</td>
                                                                <td class="res-display-none">Teach to create the replica of Google Homepage. Add all the necessary hyperlinks,
                                buttons, and tags such as images.</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 12</td>
                                                                <td>Innovate Maps</td>
                                                                <td class="res-display-none">How to code the interactive experiences?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 13</td>
                                                                <td>Tribute Page</td>
                                                                <td class="res-display-none">How to code for the Tribute Page using the HTML?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 14</td>
                                                                <td>Survey Page</td>
                                                                <td class="res-display-none">How to code to create a survey page?</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Lecture 15</td>
                                                                <td>Chatbot</td>
                                                                <td class="res-display-none">How to develop a Chatbot using the dialog flow in ML Platforms? Explain with the help
                                of practical implementation.</td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- End Courses Popup Section -->



        <!-- Course Provide Section -->
        <section class="course-provide">
            <div class="heading-txt">
                <h2>What Are We Providing In The Course</h2>
                <p>All the things that will make your child a creator</p>
            </div>
            <div class="course-provide-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="course-provide-box">
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/one-on-one.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">One on One Live Classes</p>
                                                <p class="cpb-txt-sub">
                                                    Student improves Presentation skills as they have to share their screen and
                        talk to the teacher’s in-class 1:1
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/trophy.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">Weekly competition With Fellow Students</p>
                                                <p class="cpb-txt-sub">
                                                    Crafted to make students Self-motivated and collaborative, so they don’t
                        rely on anyone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">Teacher Guidance To Build Projects</p>
                                                <p class="cpb-txt-sub">
                                                    Students can Co-ordinate and build projects with the guidance of the
                        teacher.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="course-provide-box">
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/research.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">Research Based Projects</p>
                                                <p class="cpb-txt-sub">
                                                    To increase child curiosity and Self-confident through coding because they
                                                    To increase child curiosity and Self-confident through coding because they
                        can think independently
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/activity.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">Activities In 1 Hour Classes & Projects</p>
                                                <p class="cpb-txt-sub">
                                                    We are inclined to build time management skills in the child through
                        projects and activities in 1-hour class and projects
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="cpb-left-1">
                                    <div class="row">
                                        <div class="col-xs-2 col-md-2">
                                            <div class="cpb-img">
                                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/excelent.svg" alt="courses icon" />
                                            </div>
                                        </div>
                                        <div class="col-xs-10 col-md-10">
                                            <div class="cpb-txt">
                                                <p class="cpb-txt-head">Excellent Tutors From Top Universities</p>
                                                <p class="cpb-txt-sub">
                                                    Students will be taught by the top university graduates like IITs, NITS and
                        other top professors
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="cp-cta">
                    <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS</a>
                </div>
            </div>
        </section>
        <!-- End Course Provide Section -->
        <!-- Curriculum Section -->
        <section class="curriculum">
            <div class="heading-txt">
                <h2>Meet Our Qualified Teachers</h2>
            </div>
            <div class="curriculum-container">
                <div class="container">
                    <div id="res-teachers-profile" class="owl-carousel owl-theme">
                        <div class="item">
                            <div class="curriculum-box">
                                <div class="cb-img">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher1.png" alt="Teacher's Image" />
                                </div>
                                <div class="cb-caption">
                                    <p>Sharanya</p>
                                    <!-- <span>IIT DELHI</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="curriculum-box">
                                <div class="cb-img">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher2.png" alt="Teacher's Image" />
                                </div>
                                <div class="cb-caption">
                                    <p>Sukanya Bose</p>
                                    <!-- <span>NIT INDORE</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="curriculum-box">
                                <div class="cb-img">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher3.png" alt="Teacher's Image" />
                                </div>
                                <div class="cb-caption">
                                    <p>Taniya Bajaj</p>
                                    <!-- <span>DTU</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="curriculum-box">
                                <div class="cb-img">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher4.png" alt="Teacher's Image" />
                                </div>
                                <div class="cb-caption">
                                    <p>Parna Mehta</p>
                                    <!-- <span>DTU</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="teachers-profile">
                        <div class="row">
                            <div class="col-xs-6 col-md-3">
                                <div class="curriculum-box">
                                    <div class="cb-img">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher1.png" alt="Teacher's Image" />
                                    </div>
                                    <div class="cb-caption">
                                        <p>Sharanya</p>
                                        <!-- <span>IIT DELHI</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-3">
                                <div class="curriculum-box">
                                    <div class="cb-img">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher2.png" alt="Teacher's Image" />
                                    </div>
                                    <div class="cb-caption">
                                        <p>Sukanya Bose</p>
                                        <!-- <span>NIT INDORE</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-3">
                                <div class="curriculum-box">
                                    <div class="cb-img">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher3.png" alt="Teacher's Image" />
                                    </div>
                                    <div class="cb-caption">
                                        <p>Taniya Bajaj</p>
                                        <!-- <span>DTU</span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-6 col-md-3">
                                <div class="curriculum-box">
                                    <div class="cb-img">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/teacher4.png" alt="Teacher's Image" />
                                    </div>
                                    <div class="cb-caption">
                                        <p>Parna Mehta</p>
                                        <!-- <span>DTU</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Curriculum Section -->
        <!-- Work Section -->
        <section class="work" id="work">
            <div class="heading-txt">
                <h2>How Does This Work</h2>
            </div>
            <div class="work-container">
                <div class="container">
                    <div class="row">
                        <div class="col-xs-6 col-md-3">
                            <div class="wc-box">
                                <div class="icon-box">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/work-icon-1.png" alt="work icon" />
                                </div>
                                <div class="wc-txt">
                                    <p>Register & schedule a free demo class</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-md-3">
                            <div class="wc-box">
                                <div class="icon-box">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/work-icon-2.png" alt="work-icon" />
                                </div>
                                <div class="wc-txt">
                                    <p>Attend online session with your kid</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-md-3">
                            <div class="wc-box">
                                <div class="icon-box">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/work-icon-3.png" alt="work icon" />
                                </div>
                                <div class="wc-txt">
                                    <p>If satisfied, buy a courses package </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-6 col-md-3">
                            <div class="wc-box">
                                <div class="icon-box">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/work-icon-4.png" alt="work icon" />
                                </div>
                                <div class="wc-txt">
                                    <p>Make your kid the next big name in world </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cp-cta">
                    <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS
                    </a>
                </div>
            </div>
        </section>
        <!-- End Work Section -->
        <!-- Certificate Section -->
        <section class="get-certificate">
            <div class="heading-txt">
                <h2>Get Certificate With Course Completion </h2>
            </div>
            <div class="get-certificate-container">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="gcc-img">
                                <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/certificate.jpg" alt="certificate" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="gcc-txt">
                                <p><i class="fas fa-circle"></i>&nbsp; Certify Your Kid With Course Completion</p>
                                <p><i class="fas fa-circle"></i>&nbsp; Make Your Kid College Ready</p>
                                <p>
                                    <i class="fas fa-circle"></i>&nbsp; Certificate By The Alumni Of Delhi IIT.
                                </p>
                                <p>
                                    <i class="fas fa-circle"></i>&nbsp; Improves Logical Thinking And Sharpens Problem Solving
                                </p>
                                <p><i class="fas fa-circle"></i>&nbsp; Innovative Curriculum Designed To Prepare Your Kid</p>
                                <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Certificate Section -->
        <!-- Footer Banner Section -->
        <section class="footer-banner">
            <div class="container">
                <div class="footer-banner-container">
                    <div class="fb-head-txt">
                        <p>
                            Together we take the first steps together your child’s bright future. Register today for a free session and
            start their journey in coding.
                        </p>
                    </div>
                    <div class="fb-img-txt-box">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="fb-itb-img1">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/illustr-img1.png" alt="Illustrator img" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="fb-itb-cta">
                                    <a href="" onclick="ga('send', 'event', 'Book Class', 'click_text', window.location.href, 0, 0);" class="header-jc-cta tempUrl">BOOK A FREE CLASS</a>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="fb-itb-img2">
                                    <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/illustr-img2.png" alt="Illustrator img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Footer Banner Section -->
        <!-- Footer Form Section -->
        <section class="footer-form">
            <div class="container">


                <div class="row">

                    <div class="col-md-offset-2 col-md-8">
                        <div class="footer-form-frm-sec">
                            <p class="ffts-head-txt">Do You Have Any Query</p>
                            <small>Fill out this forum to get your query solved</small>
                            <form class="mt-20 footer-frm">

                                <div class="form-group">
                                    <label for="email">Parent’s Email</label>
                                    <input type="email" class="form-control" id="leadEmail" placeholder="Parent's email" />
                                    <span style="display: none" class="spanError" id="leadEmailSpan">Please choose a valid email</span>

                                </div>
                                <div class="form-group">
                                    <label for="name">Parent’s Full Name</label>
                                    <input type="text" class="form-control" id="leadName" placeholder="Enter full name" />
                                    <span style="display: none" class="spanError" id="leadNameSpan">Please choose valid name</span>

                                </div>
                                <div class="form-group">
                                    <label for="mobile">Parent’s Mobile</label>
                                    <input type="text" id="mobile" class="form-control" placeholder="Parent's mobile number" />
                                    <span style="display: none" class="spanError" id="leadPhoneSpan">Please choose valid phone number</span>
                                </div>
                                <input type="button" data-source-id="63" id="leadSubmit" value="Submit" runat="server"
                                    class="btn btn-default header-jc-cta mt-10" />
                            </form>
                        </div>
                    </div>
                </div>
                <div class="success-message-container" id="successPopUpJH" style="display: block;">

                    <div id="myModal" class="modal fade" role="dialog">
                        <div class="modal-dialog">

                            <!-- Modal content-->
                            <div class="modal-content">
                                <div class="modal-header">
                                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                                </div>
                                <div class="modal-body">
                                    <div class="head-img">
                                        <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/success-mesg-img.svg" alt="success mesg image" />
                                    </div>
                                    <div class="smc-txt-container">
                                        <p>Hey there! We receieved your request</p>
                                        <small>Stay Tuned as we are going to contact you within 1 Hour</small>
                                        <a href="javascript:void(0)" data-dismiss="modal" type="submit" class="btn btn-default">CLOSE</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- <p>Hey there! We receieved your request</p>
                    <small>Stay Tuned as we are going to contact you within 1 Hour</small>
                    <a href="/juniorhacker.aspx" type="submit" class="btn btn-default">GO TO HOME</a> -->
                </div>
            </div>

        </section>


        <!-- End Footer Form Section -->
        <!--================  Footer Section  ================-->
        <div class="ask-footer-section"  style="display: none;">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/logo.png" alt="Footer Logo" />
                            <p class="logo-sub-txt">Unleash your child’s potential with live coding classes from top professionals in the country at affordable prices</p>
                            <p class="ask-footer-icons">
                                <a href="javascript:void()" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);"><i class="fab fa-twitter"></i></a>
                                &nbsp;&nbsp; <a href="javascript:void()"><i class="fab fa-pinterest-p"></i></a>&nbsp;&nbsp;
              <a href="javascript:void()"><i class="fab fa-youtube"></i></a>
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a href="https://www.askiitians.com/about-us.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">About Us</a></li>
                                <li><a href="https://www.askiitians.com/blog/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Blog</a></li>
                                <li><a href="https://www.askiitians.com/LiveClasses.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Live Classes</a></li>
                                <li><a href="https://www.askiitians.com/LiveClasses.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">StudyPod</a></li>
                                <li><a href="https://www.askiitians.com/packages/Packages.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Course Package</a></li>
                                <li><a href="https://www.askiitians.com/site-map.htm" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Site Map</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a href="https://www.askiitians.com/media-coverage.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Media Coverage</a></li>
                                <li><a href="https://www.askiitians.com/school-tie-up-program/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">School Tie-up</a></li>
                                <li><a href="https://www.askiitians.com/franchisee/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Franchisee</a></li>
                                <li><a href="https://www.askiitians.com/rd-sharma-solutions/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">RD Sharma Solution</a></li>
                                <li><a href="https://www.askiitians.com/Tutors_Management/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Tutor Login</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="ask-fs-txt-container">
                            <ul>
                                <li><a href="https://www.askiitians.com/careers.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Career </a></li>
                                <li><a href="https://www.askiitians.com/faqs.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">FAQ'S</a></li>
                                <li><a href="https://www.askiitians.com/privacy-policy.html" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Privacy Policy</a></li>
                                <li><a href="https://www.askiitians.com/terms-and-conditions/" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Terms & Conditions</a></li>
                                <li><a href="https://www.askiitians.com/contact-us.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Contact Us</a></li>
                                <li><a href="https://www.askiitians.com/paypalpayment.aspx" onclick="ga('send', 'event', 'JrHac_Footer_Links', 'Click_text', window.location.href, 0, 0);">Pay Now</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--================  End Footer Section  ================-->
        <div class="scroll-top">
            <img src="https://files.askiitians.com/static_content/Resources/images/jh-images/icons/launch.png" alt="launch icon" />
        </div>

    </form>
    

    
    <script src="https://files.askiitians.com/static_content/Resources/scripts/jquery-1.12.4.min.js"></script>
    <script src="https://files.askiitians.com/static_content/Resources/scripts/bootstrap.min.js"></script>
    <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
    <script src="https://files.askiitians.com/static_content/Resources/scripts/owl.carousel.js" type="text/javascript"></script>
    <script src="https://files.askiitians.com/static_content/Resources/scripts/intlTelInput.min.js"></script>
    <script src="https://files.askiitians.com/static_content/Resources/scripts/jh-script.js"></script>


    <script>
        /*---------- Mobile Input ---------*/
        var input = document.querySelector("#mobile");
        window.intlTelInput(input, {
            initialCountry: "in",
            // utilsScript: "https://files.askiitians.com/static_content/Resources/scripts/utils.js",
        });
    </script>
    <!-- <%-- <script>
        window['GoogleAnalyticsObject'] = 'ga';
        window['ga'] = window['ga'] || function () {
            (window['ga'].q = window['ga'].q || []).push(arguments)
        };
    </script>--%> -->

    <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-2121288-3"></script>
    <script>
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', 'UA-2121288-3');
        gtag('config', 'AW-588645247');
    </script>

    <script src="https://files.askiitians.com/static_content/Resources/scripts/JuniorHacker.js"></script>
    <script type="text/javascript">
        $(document).ready(function () {
            var optionsLead = {
                txtName: '#leadName',
                txtEmail: '#leadEmail',
                txtMobile: '#mobile',
                btnRegister: '#leadSubmit',

                // span values
                txtNameSpan: '#leadNameSpan',
                txtEmailSpan: '#leadEmailSpan',
                txtMobileSpan: '#leadPhoneSpan',
            };
            $.Ask.JuniorHacker(optionsLead);
        });
    </script>
</body>

