import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationType } from 'src/app/enum/NotificationType';
import { MenuListItems } from 'src/app/Models/ModelsList/menuListItem';
import { MenuModel } from 'src/app/Models/menuModel';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { FreeDemoRegistrationComponent } from 'src/app/popupModel/free-demo-registration/free-demo-registration.component';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ProductService } from 'src/app/services/product.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { LoginWithEmailComponent } from 'src/app/popupModel/login-with-email/login-with-email.component';
import { ChangePasswordComponent } from 'src/app/popupModel/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

})
export class HeaderComponent implements OnInit {
  toggleMobileMenu: boolean = false;
  isLogin: boolean = false;
  expandMenu: boolean = false;
  isVerticalLayout = true;
  mobileActiveMenu: string = 'menu';
  dekstopActiveMenu: string = '';
  userDetails: any;
  menuItems: Array<MenuModel> = [] as Array<MenuModel>;
  notesmenuItems: Array<MenuModel> = [] as Array<MenuModel>;
  scrollingClass: string = '';
  productData: any;
  isPackage: boolean = false;
  Appgradeid: any;
  currentRoute!: string;
  ifHome: boolean = false;
  showUserMenu: boolean = false;
  show = false;
  cachaSubscribe: any;
  parentList: boolean = false;
  childList: boolean = false;
  hideSideNav: boolean = false;

  constructor(
    private modalService: NgbModal,
    private cachaService: CacheService,
    private router: Router,
    private storage: StorageService,
    private userService: UserService,
    private productService: ProductService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  ngOnInit(): void {
    console.log("started");
    let IsLogin = this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL);
    this.isLogin = Boolean(IsLogin);
    this.ifHome = this.isLogin;

    this.cachaSubscribe = this.cachaService._userDetails.subscribe((res) => {
      this.isLogin = res != null;
      this.ifHome = this.isLogin;
      this.userDetails = res;
      if (res != null) {
        this.getUserOrders();
      }
    });

    this.cachaService._isUserLoggedIn.subscribe((res) => {
      this.isLogin = res;
      if (this.isLogin == false) {
        if (this.storage.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL) == 'true') {
          this.isLogin = true;
        }
      }
    });
    
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.currentRoute = ev.url;
      }
    })
  }

  getUserOrders() {
    this.productService.getOrderFromUserID(this.userDetails?.id).subscribe((data) => {
      this.productData = data.items;
      for (const item of this.productData) {
        if (
          item.userId === this.userDetails?.id &&
          item.transactionId > 0 &&
          item.packagedetail &&
          item.packagedetail.length > 0
        ) {
          this.isPackage = true;
          this.Appgradeid = item.packagedetail[0].gradeid;
        }
      }
    });
  }

  ngOnDestroy() {
    this.cachaSubscribe.unsubscribe();
  }

  expandUserMenu() {
    this.isVerticalLayout = !this.isVerticalLayout;
    this.cachaService.onExpandUserMenu(this.expandMenu);
  }

  openlogin() {
    const sourceId = SourceIdEnum.Register;
    const loginModelRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
    loginModelRef.componentInstance.sourceId = sourceId;
    loginModelRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
      if (isUserLogged) {
        this.isLogin = isUserLogged;
        this.cachaService.setIsUserLoggedIn(true);
      }
    });
  }

  logout() {
    this.storage.setItem(StorageKey.IS_LOGGED_IN, 'false', StorageType.LOCAL);
    this.cachaService.onUserDetailsChange(null);
    this.cachaService.setIsUserLoggedIn(false);
    this.storage.clear(StorageType.LOCAL);
    this.router.navigate(['']);
    this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged out!`);
  }

  onclick_exam_info() {
    this.show = false;
  }
  onclick_notes() {
    this.show = false;
  }

  over_notes() {
    this.show = !this.show;
    if (this.show) {
      this.notesmenuItems = MenuListItems.NotesMenuItems;
    } else {
      this.notesmenuItems = [];
    }
  }

  over_exam() {
    this.show = !this.show;
    if (this.show) {
      this.menuItems = MenuListItems.MenuItems;
    } else {
      this.menuItems = [];
    }
  }

  toggleParentList() {
    this.parentList = !this.parentList;
    if (this.parentList) {
      this.childList = true  
    }
    else {
      this.childList = false;
    }
  }

  toggleChildList() {
    this.childList = !this.childList;
  }

  onMouseEnterOnExamInfo() {
    this.menuItems = MenuListItems.MenuItems;
    this.show = true;
    this.childList = true;
  }

  onMouseEnterOnNotes() {
    this.notesmenuItems = MenuListItems.NotesMenuItems;
    this.show = true;
  }

  bookAFreeClass() {
    if (!this.userDetails) {
      const sourceId = SourceIdEnum.Register;
      const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.sourceId = sourceId;
    } else {
      const sourceId = SourceIdEnum.FreeDemo;
      const modalRef = this.modalService.open(FreeDemoRegistrationComponent, { centered: true, backdrop: 'static' });
      modalRef.componentInstance.UserName = this.userDetails.name;
      modalRef.componentInstance.Phone = this.userDetails.mobile;
      modalRef.componentInstance.sourceId = sourceId;
    }
  }

  receiveDataFromChild(data: boolean) {
    this.toggleMobileMenu = data;
  }

  manageSideNav(eventData: any) {          // changes on header html file 
    this.expandMenu = !this.expandMenu;
  }

  closeSideNav() {
    this.expandMenu = false;
    this.cachaService.closeSideNavigation(false);
  }
  ChangePassword()
  {
    const ChangePasswordModalRef = this.modalService.open(ChangePasswordComponent, { centered: true, backdrop: 'static' });

  }
}
