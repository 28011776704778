import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GradeModel } from 'src/app/Models/GradeModel';
import { PhoneNumber } from 'src/app/Models/PhoneNumber';
import { CourceService } from 'src/app/services/cource.service';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { StorageKey, StorageType } from 'src/app/storage-key';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheService } from 'src/app/services/cache.service';
import { Registration } from 'src/app/Models/RegistrationModel';
import { UserDetails } from 'src/app/Models/UserDetailsModel';
import { NotificationService } from 'src/app/services/notification.service';
import { NotificationType } from 'src/app/enum/NotificationType';
import { GoogleResponseModel, GoogleResponseRequestModel } from 'src/app/Models/GoogleResponseModel';
import { LoginWithEmailComponent } from '../login-with-email/login-with-email.component';
import { SourceIdEnum } from 'src/app/Models/sourceIdEnumModel';
import { phoneNumberValidator } from 'src/app/country-picker/number-validator';
import { GoogleAuthService } from '../google-signin-module/google-signin.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {
  @ViewChild('hello', { static: false }) divHello: ElementRef | any;
  @Input() sourceId!: number;
  @Input() HideHeaderDiv!: boolean;
  @Input() contentData: any;
  @Output() isUserLogged: EventEmitter<any> = new EventEmitter();
  @Output() isClosedModal: EventEmitter<any> = new EventEmitter();
  @Output() userid: EventEmitter<any> = new EventEmitter();
  phoneNumberValue: string = '';

  phoneNumber = new PhoneNumber();
  verificationCode!: string;
  user: any;
  registration!: Registration;
  userDetails!: UserDetails;
  separateDialCode = true;
  value: any;
  maxLength: any;
  returnUrl: string | undefined;
  LoginHide: boolean = false;
  SignUpHide: boolean = true;
  LoginbuttonHide: boolean = true;
  ValidatebuttonHide: boolean = false;
  SignupbuttonHide: boolean = true;
  ValidateSignupbuttonHide: Boolean = false;
  loginPhone!: string;
  userEmail!: string;
  reason!: any;
  form!: FormGroup;
  test: {} | undefined;
  userData!: {} | undefined;
  getGra: Array<GradeModel> = [];
  gID!: any;
  //add class for active button in gradlist
  isFavorite: boolean = false;
  userID!: number;
  userName!: string;
  showPhoneInput: boolean = true;
  showOTPInput: boolean = false;
  showResendOTP: boolean = false;
  mobileNumError: string = '';
  confirmationResult!: any;
  isOTPSent: boolean = false;
  isUserExists: boolean = false;
  isOTPVerfied: boolean = true;
  isUserVerified: boolean = true;
  submitted = false;
  isGoogleSignIn: boolean = true;
  isUserExistsWithEmail: boolean = true;
  googleData!: Array<any>;
  isLoginTypeEmail: boolean = false;
  isExistGoogleSignIn: boolean = false;
  updateUserData: any;
  isWritten: boolean = false;
  isAsperCountry: boolean = true;
  otp:string | undefined;
  verificationId:number | undefined;
  //google sign in
  googleResponseRequestModel!: GoogleResponseRequestModel;
  googleResponseModel!: GoogleResponseModel;
  loginType: string = 'phone';
  isSelectPhoneAndGrade: boolean = false;
  isRegistrationShow: boolean = false;
  googleClientId!:string;

  constructor(
    private courceService: CourceService,
    private userService: UserService,
    private storageService: StorageService,
    private datePipe: DatePipe,
    public activeModal: NgbActiveModal,
    private cachaService: CacheService,
    private formBuilder: FormBuilder,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private googleSigninservice: GoogleAuthService

  ) {
  }

  ngOnInit() {
    this.courceService.getAllGrades().subscribe((res) => {
      this.getGra = res;
    });
    
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]],
      phone: ['', [Validators.required]],
      grade: ['', Validators.required,],
      verificationCode1: ['', Validators.required],
      verificationCode2: ['', Validators.required],
      verificationCode3: ['', Validators.required],
      verificationCode4: ['', Validators.required],
      verificationCode5: ['', Validators.required],
      verificationCode6: ['', Validators.required],
    });
  }
  get f() {

    return this.form.controls;
  }

  ischange() {
    this.isWritten = true;
    this.isAsperCountry = true;
  }
  otpAddition() {
    this.verificationCode =
      this.form.value.verificationCode1 +
      this.form.value.verificationCode2 +
      this.form.value.verificationCode3 +
      this.form.value.verificationCode4 +
      this.form.value.verificationCode5 +
      this.form.value.verificationCode6;
  }


  // changePreferredCountries() {
  //   // this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  // }

  transformDate(date: string | number | Date) {
    return this.datePipe.transform(date, "yyyy-MM-ddThh:mm:ss.SSS'Z'");
  }

  verifyLoginCode(isUserExists: boolean) {
    this.otpAddition();
    this.confirmationResult
      .confirm(this.verificationCode)
      .then((result: { user: any }) => {
        this.user = result.user;
        this.isOTPVerfied = true;
        if (isUserExists == true) {
          this.registration = {
            mobile: this.form.value.phone,
            userTypeId: 1,
            sourceNameId: this.sourceId,
            ipAddress: '',
            city: '',
            ipCountry: '',
            VerificationId:this.verificationId,
             otp: this.otp,
            userDetails: {
              id: 0,
              grade: this.gID,
              userId: this.userID,
              schoolName: "",
              modifiedDate: this.transformDate(new Date())?.toString(),
            },
            mobileVerification:
      {
        verificationId:0
      }
          }
          this.userService.updatePhoneGradeDetails(this.registration).subscribe(() => {
            this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
            this.activeModal.close();
            this.notificationService.notifyMessage(NotificationType.Success, `You're successfully logged in!`);
            if (this.userID) {
              this.userid.emit(this.userID);
            }
          });
        }
        else {
          this.validateRegister();
        }
      })
      .catch((error: any) => {
        this.isOTPVerfied = false;
        this.showOTPInput = true;
        this.isUserVerified = false;
        if (this.isOTPVerfied == false) {
          this.form.controls['verificationCode1'].reset();
          this.form.controls['verificationCode2'].reset();
          this.form.controls['verificationCode3'].reset();
          this.form.controls['verificationCode4'].reset();
          this.form.controls['verificationCode5'].reset();
          this.form.controls['verificationCode6'].reset();
        }
       
      });
  }

  resendOTP() {
    //open the modal
    this.showPhoneInput = false;
    this.isOTPVerfied = true;

    this.form.controls['verificationCode1'].reset();
    this.form.controls['verificationCode2'].reset();
    this.form.controls['verificationCode3'].reset();
    this.form.controls['verificationCode4'].reset();
    this.form.controls['verificationCode5'].reset();
    this.form.controls['verificationCode6'].reset();
  }

  SendToAnotherNumber() {
    this.showPhoneInput = true;
    this.showOTPInput = false;
    this.showResendOTP = false;
  }

  getgradeID(event: any, id: any) {
    let parent = event.currentTarget;
    const StrToNum = parent.id;
    var y: number = +StrToNum;
    if (y === id) {
      this.isFavorite = true;
    } else {
      this.isFavorite = false;
    }
    this.gID = id;
  }

  myMethod(e: any) {
    this.submitted = true;
    // this.form.controls['phone'].markAllAsTouched();
    if (this.form.controls['phone'].invalid) {
      return;
    }
    else {
      this.showOTPInput = false;
      const pattern = /[0-9\+\-\ ]/;

      let inputChar = String.fromCharCode(e.charCode);
      if (e.keyCode != 8 && !pattern.test(inputChar)) {
        e.preventDefault();
      }
      this.loginPhone = this.form.value.phone;
      const isValid = phoneNumberValidator(this.loginPhone);

      if (isValid == true) {
        this.isAsperCountry = true;
        this.userService.checkUser(this.loginPhone).subscribe(
          {
            next: (data) => {
              if (data.id > 0) {
                if (this.googleResponseRequestModel == undefined) {
                  if (this.SignUpHide == false) {
                    this.SignUpHide = true;
                    this.LoginHide = false;
                    //display msg like you have already an account so please goto login
                    this.LoginbuttonHide = false;
                    this.SignupbuttonHide = true;
                  }
                  this.userID = data.id;
                  this.gID = data.grade;
                  this.isUserExists = true;
                  this.isUserExistsWithEmail = true;
                  this.LoginbuttonHide = true;

                }
                else {
                  this.userID = data.id;
                  this.gID = data.grade;
                  this.registration = {
                    mobile: this.form.value.phone,
                    userTypeId: 1,
                    sourceNameId: this.sourceId,
                    ipAddress: '',
                    city: '',
                    ipCountry: '',
                    VerificationId:this.verificationId,
                     otp: this.otp,
                    userDetails: {
                      id: 0,
                      grade: this.gID,
                      userId: this.userID,
                      schoolName: "",
                      modifiedDate: this.transformDate(new Date())?.toString(),
                    },mobileVerification:
                    {
                      verificationId:0
                    }
                  }
                  this.userService.checkUser(this.loginPhone).subscribe((data: any) => {
                    if (data.id > 0) {
                      this.notificationService.notifyMessage(NotificationType.Warning, `You're already Register with this number!`);
                    }
                    else {
                      this.userService.updatePhoneGradeDetails(this.registration).subscribe((data: any) => {
                        this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
                        this.cachaService.setIsUserLoggedIn(true);
                        this.getUserDetailsById(this.userID);
                        this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
                        this.activeModal.close();
                        this.notificationService.notifyMessage(NotificationType.Success, `You're successfully Registered!`);
                        if (this.userID) {
                          this.userid.emit(this.userID);
                        }
                      });
                    }
                  });

                }
              }
              else {
                if (this.googleResponseRequestModel == undefined || this.googleResponseRequestModel.email == null) {
                  this.LoginHide = true;
                  this.SignUpHide = false;
                  this.SignupbuttonHide = false;
                  this.isUserExists = false;
                }
                else {
                  this.userService.checkEmailId(this.googleResponseRequestModel.email).subscribe(userData => {
                    if (userData.length > 0) {
                      this.userID = userData[0].Id;
                      this.LoginHide = false;
                      this.SignUpHide = true;
                      this.SignupbuttonHide = true;
                      this.isUserExists = true;
                    }
                    else {
                      this.LoginHide = true;
                      this.SignUpHide = false;
                      this.SignupbuttonHide = false;
                      this.isUserExists = false;
                    }
                  });
                }
              }
            },
            error: () => {
              if (this.googleResponseRequestModel == undefined || this.googleResponseRequestModel.email == null) {
                this.LoginHide = true;
                this.SignUpHide = false;
                this.SignupbuttonHide = false;
                this.isUserExists = false;
              }
              else {
                this.userService.checkEmailId(this.googleResponseRequestModel.email).subscribe(userData => {
                  if (userData.length > 0) {
                    this.userID = userData[0].Id;
                    this.LoginHide = false;
                    this.SignUpHide = true;
                    this.SignupbuttonHide = true;
                    this.isUserExists = true;
                  }
                  else {
                    this.LoginHide = true;
                    this.SignUpHide = false;
                    this.SignupbuttonHide = false;
                    this.isUserExists = false;
                  }
                });
              }
            }
          });
      }
      else {
        this.isAsperCountry = false;
      }
    }
  }

  login() {
    this.showOTPInput = false;
    this.loginPhone = this.form.value.phone;
    if (this.form.value.email == "" || this.form.value.email == undefined) {
      this.userEmail = this.googleResponseRequestModel?.email ?? '';
    }
    else {
      this.userEmail = this.form.value.email;
    }
    if (this.userEmail) {
      this.userService.checkEmailId(this.userEmail).subscribe(userData => {
        if (userData.length > 0) {
          if (userData[0].Email != '' && userData[0].Mobile != '') {
            this.notificationService.notifyMessage(NotificationType.Warning, `You are Already Registered with this Email Id or Mobile Number!`);
          }
          else {
            if (this.isUserExists == true || this.isUserExistsWithEmail == true) {
              if (this.isOTPSent == true) {
                this.verifyLoginCode(this.isUserExists);
              }
              else {
                this.showResendOTP = true;
              }
            } else {
              if (this.form.controls['name'].invalid || this.form.controls['email'].invalid || this.form.controls['grade'].invalid) {
                return;
              }
              else {
                //sign up process start
                this.LoginHide = true;
                this.SignUpHide = false;
                this.verifyLoginCode(this.isUserExists);
              }
            }
          }
        }
        else {
          if (this.isUserExists == true || this.isUserExistsWithEmail == true) {
            if (this.isOTPSent == true) {
              this.verifyLoginCode(this.isUserExists);
            }
            else {
              this.showResendOTP = true;
            }
          } else {
            if (this.form.controls['name'].invalid || this.form.controls['email'].invalid || this.form.controls['grade'].invalid) {
              return;
            }
            else {
              //sign up process start
              this.LoginHide = true;
              this.SignUpHide = false;
              this.verifyLoginCode(this.isUserExists);
            }
          }
        }
      });
    }
    else {
      if (this.isUserExists == true || this.isUserExistsWithEmail == true) {
        if (this.isOTPSent == true) {
          this.verifyLoginCode(this.isUserExists);
        }
        else {
          this.showResendOTP = true;
        }
      } else {
        if (this.form.controls['name'].invalid || this.form.controls['email'].invalid || this.form.controls['grade'].invalid) {
          return;
        }
        else {
          //sign up process start
          this.LoginHide = true;
          this.SignUpHide = false;
          this.verifyLoginCode(this.isUserExists);
        }
      }
    }
  }

  passBackUserData(userData: any) {
    this.isUserLogged.emit(userData);
  }

  LoginBtn() {
    this.LoginHide = false;
    this.SignUpHide = true;
    this.showOTPInput = false;
    this.LoginbuttonHide = true;
    this.ValidatebuttonHide = false;
  }

  SignupBtn() {
    this.LoginHide = true;
    this.showOTPInput = false;
    this.SignUpHide = false;

  }

  closeModal() {
    this.showOTPInput = false;
    this.isClosedModal.emit('true');
    this.activeModal.close();
  }

  getUserDetailsById(id: number) {
    this.userService.getUserFromID(id).subscribe(res => {
      this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
      this.storageService.setItem(StorageKey.USER, JSON.stringify(res), StorageType.LOCAL);
      this.cachaService.onUserDetailsChange(res);
    });
  }
  validateRegister() {

    this.registration = {
      id: 0,
      name: this.form.value.name,
      email: this.form.value.email,
      isActive: true,
      userTypeId: 1,
      mobile: this.form.value.phone,
      createdDateTime: this.transformDate(new Date())?.toString(),
      modifiedBy: "",
      lastModified: this.transformDate(new Date())?.toString(),
      registrationUrl: String(window.location.pathname),
      sourceNameId: this.sourceId,
      ipCountry: '',
      ipAddress: '',
      city: '',
      VerificationId:this.verificationId,
      otp: this.otp,
      userDetails: {
        id: 0,
        grade: this.addGradeID(),
        userId: 0,
        schoolName: "",
        modifiedDate: this.transformDate(new Date())?.toString(),
      },
      mobileVerification:
      {
        verificationId:0
      }
    }

    if (this.registration.mobile != null) {

      this.userService.create(this.registration).subscribe(data => {
        if (data.result.value.id != null) {
          this.userID = data.result.value.id;
          this.storageService.setItem(StorageKey.IS_LOGGED_IN, 'true', StorageType.LOCAL);
          this.cachaService.setIsUserLoggedIn(true);
          this.getUserDetailsById(this.userID);
          this.activeModal.close();
          this.notificationService.notifyMessage(NotificationType.Success, `You're Register Successfully!`);
        }
        else { this.notificationService.notifyMessage(NotificationType.Success, `Sorry ! Unable to Register you!`); }
      });
    }
  }
  addGradeID() {
    return this.gID;
  }
  keyUpEvent(e: any) {
    var target = e.target;

    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;

    if (myLength >= maxLength) {
      var next = target;
      while (next = next.nextElementSibling) {
        if (next == null)
          break;
        if (next.tagName.toLowerCase() == "input") {
          next.focus();
          break;
        }
      }
    } else if (myLength < maxLength) {
      var prev = target;
      while (prev = prev.previousElementSibling) {
        if (prev == null)
          break

        if (prev.tagName.toLowerCase() == "input") {
          prev.focus();
          break;
        }
      }
    }
  }

  //google sign in
  onGoogleSigninSuccess(data: any) {
  this.googleClientId = "872727131907-hlpgb5puoq6g463dv1te0sfe3t1519iu.apps.googleusercontent.com";
    const sourceId = SourceIdEnum.GoogleSignIn;
    this.googleResponseModel = data;

    this.googleResponseRequestModel = {
      email: data.email,
      name: data.name,
      grade: '',
      mobile: '',
      password: '',
      profilePictureUrl: data.picture,
      registrationUrl: String(window.location.pathname),
      sourceNameId: sourceId,
      ipAddress: '',
      isMobile: false,
      ipCountry: '',
      city: '',
      googleUniqueId: '',
      accessToken: '',
      // expiresAt: '',
      // expiresIn: '',
      // firstIssuedAt: '',
      // providerId: data.Sc != undefined ? data.Sc.idpId : data.Tc.idpId,
      // scope: data.Sc != undefined ? data.Sc.scope : data.Tc.scope
    };

    this.userService.saveGoogleResponse(this.googleResponseRequestModel).subscribe(res => {
      if (res.id != null || res.id > 0) {
        if (res.isFirstTimeSignIn == false) {
          if (res.mobile == "" || res.mobile == undefined) {
            this.loginType = 'gradeAndContact';
            this.showPhoneInput = true;
            this.isGoogleSignIn = false;
            this.LoginbuttonHide = true;
            this.SignUpHide = true;
            this.isExistGoogleSignIn = true;
          }
          else {
            this.userID = res.id;
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.activeModal.close();
            this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
            this.notificationService.notifyMessage(NotificationType.Success, `Logged In SuccessFully`);
          }
        }
        else {
          if (res.mobile == "" || res.mobile == undefined) {
            this.loginType = 'gradeAndContact';
            this.showPhoneInput = true;
            this.isGoogleSignIn = false;
            this.LoginbuttonHide = true;
            this.SignUpHide = true;
            this.isExistGoogleSignIn = true;
          }
          else {
            this.userID = res.id;
            this.cachaService.setIsUserLoggedIn(true);
            this.getUserDetailsById(this.userID);
            this.activeModal.close();
            this.isUserLogged.emit(this.storageService.getItem(StorageKey.IS_LOGGED_IN, StorageType.LOCAL));
            this.notificationService.notifyMessage(NotificationType.Success, `Logged In SuccessFully`);
          }

        }
      } else {
        this.notificationService.notifyMessage(NotificationType.Error, `Login Failed`);
      }
    });
  }

  usingEmailBtn() {
    this.activeModal.close();
    const sourceId = SourceIdEnum.Register;
    const modalRef = this.modalService.open(LoginWithEmailComponent, { centered: true, backdrop: 'static' });
    modalRef.componentInstance.sourceId = sourceId;
    modalRef.componentInstance.isUserLogged.subscribe((isUserLogged: any) => {
      if (isUserLogged) {
        modalRef.componentInstance.userid.subscribe((userid: any) => {
          this.isUserLogged.emit(true);
          this.userid.emit(userid);
          this.activeModal.close();
        });
      }
      else {

      }
    });
  }

  checkUserExistWithMobileAndEmail() {
    if (this.form.value.email == "" || this.form.value.email == undefined) {
      this.userEmail = this.googleResponseRequestModel.email;
    }
    else {
      this.userEmail = this.form.value.email;
    }

    this.userService.checkEmailId(this.userEmail).subscribe(isExistUser => {
      if (isExistUser.length > 0) {
        this.getUserDetailsById(isExistUser[0].Id);
      }
    });
  }
}