<form [formGroup]="changePasswordForm">
  <div class="modal-header close_btn_align">
    <button type="button" class="btn-close btn-close-black" data-bs-dismiss="modal"
      (click)="closeModal()"></button>
  </div>
  <div class="PassChangeWrap">
    <h1>Change Password</h1>
    <div class="form-group">
      <label>Old Password</label>
      <input formControlName="oldPwd" id="oldPwd" type="password" class="form-control" (keyup)="toggleAlert()"/>
      <div class="alert alert-danger" *ngIf="oldPwd?.touched && oldPwd?.invalid">
        <div *ngIf="oldPwd?.errors.required">Old Password is required.</div>
      </div>
      <div class="alert alert-danger" *ngIf="!isOldPawordValid">Old Password is not valid.</div>
    </div>

    <div class="form-group">
      <label>New Password</label>
      <input formControlName="newPwd" id="newPwd" type="password" class="form-control" />
      <div class="alert alert-danger" *ngIf="newPwd?.touched && newPwd?.invalid">
        <div *ngIf="newPwd.errors.required">New Password is required.</div>
      </div>
    </div>

    <div class="form-group">
      <label>Confirm Password</label>
      <input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />
      <div class="alert alert-danger" *ngIf="confirmPwd?.touched && confirmPwd?.invalid">
        <div *ngIf="confirmPwd.errors.required">Confirm Password is required.</div>
      </div>
      <div class="alert alert-danger" *ngIf="confirmPwd?.valid && changePasswordForm.invalid">
        Passwords do not match.
      </div>
    </div>

    <button class="btn btn-change-password" (click)="ChangePassword()" [disabled]="changePasswordForm.invalid || isCPBtnDisable">Change
      Password</button>
  </div>
</form>